export const DocumentBottomIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#323232"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M7.5 3H5a2 2 0 0 0-2 2v2.5M3 16.5V19a2 2 0 0 0 2 2h2.5M16.5 21H19a2 2 0 0 0 2-2v-2.5M21 7.5V5a2 2 0 0 0-2-2h-2.5"
    />
    <rect width={10} height={1.5} x={7} y={16.35} fill="#323232" rx={0.75} />
  </svg>
)
