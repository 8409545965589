export const OrangeTitleIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={126}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#F46D00"
      strokeLinecap="round"
      strokeWidth={12}
      d="M6.448 17.685c16.288-6.06 61.827-18.07 113.24-5.64"
    />
  </svg>
)
