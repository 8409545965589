import { useController, useFormContext } from 'react-hook-form'
import { RadioGroup as MuiRadioGroup } from '@mui/material'
import {
  Container,
  CustomFormControlLabel,
  CustomRadio,
  ErrorMessage,
} from './styles'

interface Option {
  label: string
  value: string
}

interface RadioGroupProps {
  name: string
  options: Option[]
  disabled?: boolean
  isRow?: boolean
}

export const RadioGroup: React.FC<RadioGroupProps> = ({ name, options, disabled, isRow = true }) => {
  const { control } = useFormContext()
  const {
    field,
    formState: { errors },
  } = useController({ name, control, defaultValue: '' })

  const handleFileType = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange((event.target as HTMLInputElement).value)
  }

  return (
    <Container>
      <MuiRadioGroup
        row={isRow}
        aria-labelledby="controlled-radio-buttons-group"
        name="fileType"
        value={field.value}
        onChange={handleFileType}
      >
        {options.map(({ label, value }) => (
          <CustomFormControlLabel
            key={value}
            value={value}
            control={<CustomRadio />}
            label={label}
            disabled={disabled}
          />
        ))}
      </MuiRadioGroup>
      <ErrorMessage>{errors[name]?.message as string}</ErrorMessage>
    </Container>
  )
}
