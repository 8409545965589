export const SuccessIcon = (props: any) => (
  <svg
    width="62"
    height="62"
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="31" cy="31" r="31" fill="#00B22A" />
    <path
      d="M21.3164 31.7479L27.2786 37.4567L27.2401 37.4198L40.6914 24.54"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
