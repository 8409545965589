import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { ConnectionForm, useGetCustomer } from 'features/connection-form'

import { SentFormPage, StartFormPage, ErrorFormPage } from './components'
import { AxiosError } from 'axios'
import { useGlobalData } from 'hooks'

export function ConnectionFormPage() {
  const [searchParams] = useSearchParams()
  const sponsorId = searchParams.get('id') || ''
  const indcli = searchParams.get('cli') || ''
  const customerId = searchParams.get('cliente') || ''
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { setCustomer, setAccountType, formState, setFormState, setIsLoading } = useGlobalData()
  const { data: customer } = useGetCustomer(
    { customerId, consultantId: sponsorId },
    {
      enabled: customerId?.length > 0 && !!sponsorId,
    }
  )

  useEffect(() => {
    if (!customer) return

    setFormState('fill')
    setCustomer(customer)

    if (customer.cnpj.length) {
      return setAccountType('PJ')
    }

    return setAccountType('PF')
  }, [customer])

  const onSubmitForm = () => {
    setFormState('sent')
  }

  const onGoStart = () => setFormState('start')

  const handleError = (error: AxiosError) => {
    setIsLoading(false) 
    setFormState('error')
    if (!error.response) return
    // @ts-ignore
    const errorMessage = error.response.data?.error.message
    setErrorMessage(errorMessage)
  }

  if (formState === 'start')
    return <StartFormPage onStart={() => setFormState('fill')} />

  if (formState === 'sent') return <SentFormPage />

  if (formState === 'error')
    return <ErrorFormPage errorMessage={errorMessage} />

  return (
    <ConnectionForm
      sponsorId={sponsorId}
      indcli={indcli}
      onSubmit={onSubmitForm}
      onError={handleError}
      onGoStartPage={onGoStart}
    />
  )
}
