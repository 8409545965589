import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`

export const FormLayout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
`

export const Text = styled.p`
  margin: 0;
  color: #181d11;
  font-family: 'Nexa Bold';
  font-size: 16px;
  font-weight: 700;
`

export const HiddenInput = styled.input`
  display: none;
`

export const StandbyMessage = styled.span`
  color: #d32f2f;
  font-family: 'Nexa Bold';
`;