import { ReactNode } from 'react'

import { FormProvider, UseFormReturn } from 'react-hook-form'

interface FormProps extends UseFormReturn {
  children: ReactNode;

  className?: string;

  onSubmit(): void;
}

export function Form({ children, onSubmit, className, ...props }: FormProps) {
  return (
    <FormProvider {...props}>
      <form onSubmit={onSubmit} className={className} noValidate>
          {children}
      </form>
    </FormProvider>
  )
}