import { ReactNode } from 'react'

import { Box, useMediaQuery } from '@mui/material'

import { DashedDialog } from 'assets/svg/dashed-dialog.tsx'

import { TooltipContainer, SavingsInfo } from './styles'
import styles from './styles.module.scss'

interface ShowSavingsSliderProps {
  onChange?: (newValue: number) => void
  value: number
}

export function ShowSavingsSlider({ value }: ShowSavingsSliderProps) {
  const isTablet = useMediaQuery('(max-width:1023px)')

  const ValueTooltip = (1.5 * value).toFixed(2)

  return (
    <Box className={styles.showSavingsSlider}>
      {value > 0 && (
        <>
          {!isTablet && (
            <Tooltip>
              Baseado no seu gasto, você poderá economizar até{' '}
              <span>R${ValueTooltip}</span> por ano na sua conta de luz.
            </Tooltip>
          )}

          {isTablet && value > 0 && (
            <SavingsInfo>
              <p>
                Baseado no seu gasto, você poderá economizar até{' '}
                <span>R${ValueTooltip}</span> por ano na sua conta de luz.
              </p>
            </SavingsInfo>
          )}
        </>
      )}
    </Box>
  )
}

export function Tooltip({
  open = true,
  children,
}: {
  open?: boolean
  children: ReactNode
}) {
  return (
    <TooltipContainer open={open}>
      <p>{children}</p>
      <DashedDialog width={318} height={170} />
    </TooltipContainer>
  )
}
