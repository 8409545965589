import styled from 'styled-components'
import { OrangeTitleIcon, MiniOrangeTitleIcon } from 'assets/svg'

export const MainContainer = styled.div`
  background-color: #ffffff;
  width: 100%;  
  display: block;
  overflow: auto;
  
  @media (min-width: 768px) {
    position: relative;
    overflow: hidden;
  }

  @media screen {
    height: 100vh; 
  }

`

export const IgreenLogo = styled.img`
  width: 85px;
  margin-bottom: 32px;

  @media (min-width: 768px) {
    width: 128px;
    height: 42px;
    margin-bottom: 48px;
  }

  @media (min-width: 1440px) {
    margin-bottom: 72px;
  }
`

export const ConsumptionInputContainer = styled.div`
  width: 100%;

  @media (max-width: 576px) {
    & > div {
      margin: 0 auto;
      width: 100%;
    }
  }

  @media (min-width: 577px) and (max-width: 769px) {
    display: flex;
    justify-content: center;

    & > div {
      margin: 0 auto;
      width: 75%;
    }
  }

  & > div {
    margin: 0 auto;
  }

  input {
    text-align: right;
  }
`;

export const Content = styled.div`
  padding: 40px 24px;
  position: relative;
  z-index: 1;

  @media (min-width: 768px) {
    padding: 47px 80px;
    max-width: 642px;
  }

  @media (min-width: 1024px) {
    max-width: 530px;
  }

  @media (min-width: 1440px) {
    padding: 48px 40px;
    max-width: 642px;
  }
`

export const SolarPanelImg = styled.img`
  position: absolute;
  display: none;
  bottom: 0;

  @media (min-width: 768px) {
    display: block;
    right: 0;
  }

  @media (min-width: 1024px) {
    right: -300px;
    width: 1000px;
  }
  @media (min-width: 1440px) {
    right: 0;
    width: auto;
  }
`

export const SolarIconContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;

  @media (min-width: 768px) {
    display: none;
  }
`

export const SolarPanelMobile = styled.img`
 
  @media ( height > 680px ) {
    position: absolute; 
    bottom: 0;
  }

  width: 100%;
`

export const OrangeEllipseImg = styled.img`
  position: absolute;
  bottom: 0;
  right: 5px;
  display: none;

  @media (min-width: 1024px) {
    display: block;
  }
`

export const ActiveIgreenImg = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;

  @media (min-width: 1024px) {
    display: block;
    bottom: -110px;
  }
`

export const IgreenShadowImg = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 auto;
  left: 0;
  display: none;
  width: 85px;

  @media (min-width: 1024px) {
    display: block;
  }
`

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (min-width: 1024px) {
    padding-right: 170px;
    gap: 12px;
  }
  @media (min-width: 1024px) {
    padding-right: 0;
  }
`

export const MainTitle = styled.h1`
  color: #181d11;
  font-family: 'Nexa Bold';
  font-size: 26px;
  font-style: normal;
  line-height: 32px;
  margin: 0;
  margin-bottom: 12px;

  span {
    font-family: 'Nexa Bold';
    font-size: 26px;
    position: relative;
    z-index: 1;
  }

  @media (min-width: 1024px) {
    font-family: 'Nexa Bold';
    line-height: 66px;
    letter-spacing: -2.24px;
    font-size: 56px;
    padding-right: 100px;

    span {
      font-size: 56px;
    }
  }
  @media (min-width: 1024px) {
    padding-right: 0;
  }
`

export const CurveLine = styled(OrangeTitleIcon)`
  position: absolute;
  left: 0;
  top: 35px;
  z-index: -1;
`

export const MiniCurveLine = styled(MiniOrangeTitleIcon)`
  position: absolute;
  left: 0;
  top: 17px;
  z-index: -1;
  @media (min-width: 768px) {
    top: 13px;
  }
`

export const Subtitle = styled.p`
  color: #626c75;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
`

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  h3 {
    color: #181D11;
    font-family: Nexa Bold;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    margin: 0;
  }

  @media (max-width: 767px) {
    align-items: center;
  }
`

export const Text = styled.p`
  margin: 0;
  color: #181d11;
  font-family: 'Nexa Bold';
  font-size: 16px;
  line-height: 24px;
`

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const SelectAccountTypeContainer = styled.div`
  display: flex;
  gap: 12px;
  width: 100%;
  max-width: 420px;

  button {
    border-radius: 16px;
    border: 0.5px solid #dadada;
    background: #fff;
    box-shadow: 0px 10px 42px 0px rgba(0, 0, 0, 0.04);
    height: 56px;
    display: flex;
    justify-content: center;
    line-height: 48px;
    color: #181d11;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
    padding: 16px;
    width: 100%;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  @media (min-width: 1024px) {
    button {
      height: 48px;
    }
  }
`
