import { useEffect } from 'react'

import { useLocalStorage } from 'usehooks-ts'
import * as z from 'zod'

import { usePostLogin } from '../api'
import { isValid } from 'utils'

const schemaParams = z.object({
  idconsultor: z.number(),
})

type TypeUseAuthParams = z.infer<typeof schemaParams>

interface UseAuthParams {
  sponsorId: number
}

export function useAuth({ sponsorId }: UseAuthParams) {
  const [authToken, setAuthToken] = useLocalStorage('igreen-token', '')

  const { mutate: onLogin, isLoading } = usePostLogin()

  const handleLogin = (params: TypeUseAuthParams) => {
    onLogin(params, {
      onSuccess: (data) => setAuthToken(data?.auth.access),
    })
  }

  const isTokenValid = isValid.token(authToken)

  useEffect(() => {
    if (isLoading) return

    if (!sponsorId) return

    if (isTokenValid) return

    handleLogin({ idconsultor: sponsorId })
  }, [sponsorId, isTokenValid])

  return {
    handleLogin,
    token: authToken,
    isLoading,
  }
}
