export function getFileExtensionFromBase64(base64Data: string) {
  if (!base64Data?.length) return

  const binaryData = atob(base64Data)

  const dataView = new DataView(new ArrayBuffer(binaryData.length))

  for (let i = 0; i < binaryData.length; i++) {
    dataView.setUint8(i, binaryData.charCodeAt(i))
  }

  const fileSignatureMapping: Record<string, string> = {
    'FFD8FFE0': 'jpg',
    'FFD8FFE1': 'jpg',
    '89504E47': 'png',
    '47494638': 'gif',
    '25504446': 'pdf',
    '424D': 'bmp',
  }

  function getFileExtension(dataView: any) {
    const signatureBytes = []

    for (let i = 0; i < 4; i++) {
      signatureBytes.push(
        dataView.getUint8(i).toString(16).toUpperCase().padStart(2, '0')
      )
    }

    const signature = signatureBytes.join('')
    return fileSignatureMapping[signature] || 'unknown'
  }

  return getFileExtension(dataView)
}
