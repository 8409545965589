type DataRecord = Record<string, any>;

export const accountStateFormDefaultValues = ({
  initialData,
  currentData,
}: {
  initialData: DataRecord;
  currentData: DataRecord;
}) => {
  const getDefaultValue = (key: string) => currentData[key] || initialData[key] || '';

  return {
    ufconsumo: getDefaultValue('ufconsumo'),
    accountType: initialData.cpf_cnpj?.length ? 'PF' : 'PJ',
    cnpj: getDefaultValue('cnpj'),
    cpf_cnpj: getDefaultValue('cpf_cnpj'),
  };
}
