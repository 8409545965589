import { forwardRef } from "react";
import { useController, useFormContext } from "react-hook-form";
import { InputAdornment } from "@mui/material";
import { IMaskInput } from "react-imask";

import { SuccessIconSmall } from "assets/svg";
import { MuiTextField } from "./styles";

interface CustomProps {
  onChange: (value: string) => void;
  name: string;
  mask?: RegExp | any;
  radix?: string;
  thousandsSeparator?: string;
  scale?: number;
  padFractionalZeros?: boolean;
}

const TextMaskCustom = forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(
    { onChange, mask, radix, thousandsSeparator, scale, padFractionalZeros, ...props },
    ref
  ) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (mask) return;
      onChange(e.target.value);
    };

    return (
      <IMaskInput
        {...props}
        padFractionalZeros={padFractionalZeros}
        scale={scale}
        mask={mask}
        inputRef={ref}
        onAccept={onChange}
        onChange={handleChange}
        radix={radix}
        thousandsSeparator={thousandsSeparator}
        overwrite
        normalizeZeros={false}
      />
    );
  }
);

const SuccessAdornment = () => (
  <InputAdornment position="end">
    <SuccessIconSmall />
  </InputAdornment>
);

interface TextFieldProps {
  name: string;
  label: string;
  placeholder?: string;
  mask?: string | any;
  required?: boolean;
  startAdornment?: JSX.Element | string;
  endAdornment?: JSX.Element | string;
  disabled?: boolean;
  value?: any;
  radix?: string;
  thousandsSeparator?: string;
  maxLength?: number;
  type?: string;
  onPaste?: React.ClipboardEventHandler<HTMLDivElement>;
  scale?: number;
  padFractionalZeros?: boolean;
}

export const TextField: React.FC<TextFieldProps> = ({
  name,
  label,
  placeholder,
  mask,
  required,
  startAdornment,
  endAdornment,
  disabled,
  thousandsSeparator,
  radix,
  maxLength = 255,
  type,
  scale,
  padFractionalZeros,
  ...props
}) => {
  const { control } = useFormContext();
  const {
    field,
    fieldState: { invalid, isDirty },
    formState: { errors },
  } = useController({ name, control, defaultValue: "" });
  const errorMessage = errors[name]?.message;
  const fieldIsValid = isDirty && !invalid && required;

  return (
    <MuiTextField
      {...props}
      sx={{
        ".MuiFilledInput-root": {
          border: `1px solid ${fieldIsValid ? "#00b22a" : "#808080"}`,
        },
      }}
      required={required}
      name={name}
      label={label}
      placeholder={placeholder}
      value={field.value}
      onChange={(e) => field.onChange(e)}
      InputProps={{
        inputComponent: mask ? (TextMaskCustom as any) : undefined,
        inputProps: mask
          ? {
              mask,
              radix,
              thousandsSeparator,
              maxLength,
              scale,
              padFractionalZeros,
            }
          : undefined,
        disableUnderline: true,
        startAdornment: startAdornment && (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
        endAdornment:
          fieldIsValid && !endAdornment ? <SuccessAdornment /> : endAdornment,
      }}
      fullWidth
      variant="filled"
      error={!!errors[name]}
      helperText={errorMessage as string}
      disabled={disabled}
      type={type}
    />
  );
};
