import { imageBackground, imageErrorForm } from 'assets'
import { Subtitle, Title } from 'components'
import { GreenIcon } from 'assets/svg'

import styles from './styles.module.scss'
import { useGlobalData } from 'hooks'

const defaultErrorMessage = `Baseado nos dados da sua conta não podemos completar o seu cadastro, mas
fique tranquilo! Estamos constantemente evoluindo para atender a
todos e em breve poderemos tornar esta realidade possível para você.
Agradecemos por sua compreensão e paciência.`

export function ErrorFormPage({ errorMessage }: { errorMessage: string }) {
  const { eligibilityErrors } = useGlobalData()
  return (
    <div>
      <div className={styles.startFormContainer}>
        <img className={styles.imageBackground} src={imageBackground} />

        <div className={styles.content}>
          <GreenIcon />

          <Title>Ei, infelizmente ainda não é sua hora...</Title>

          <Subtitle>{errorMessage || defaultErrorMessage}</Subtitle>
          {!!eligibilityErrors.length && (
            <div className={styles.flexCol}>
              <p className={styles.text}>Motivos:</p>
              {eligibilityErrors.map((message: string, index: number) => (
                <Subtitle key={`${message}-${index}`}>{message}</Subtitle>
              ))}
            </div>
          )}
        </div>

        <img className={styles.imageErrorForm} src={imageErrorForm} />
      </div>
    </div>
  )
}
