import styled, { css } from 'styled-components'

const baseContainerStyles = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const Container = styled.div`
  ${baseContainerStyles}
`

export const ClickableContainer = styled.label<{
  $success?: boolean
  $error?: boolean
  $disabled?: boolean
}>`
  ${baseContainerStyles}
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border-radius: 12px;
  border: 1px dashed #989c92;
  background: #fff;
  width: 100%;
  padding: 32px 0;
  gap: 12px;

  &:hover {
    border: 1px dashed #00b22a;
    color: #00b22a;

    p {
      color: #00b22a;
    }
  }

  ${({ $success }) =>
    $success &&
    css`
      border: 1px solid #00b22a;

      &:hover {
        border: 1px solid #00b22a;
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.8;

      &:hover {
        p {
          color: #181d11;
        }
      }
    `}

  ${({ $error }) =>
    $error &&
    css`
      border: 1px dashed #d32f2f;

      &:hover {
        border: 1px dashed #d32f2f;

        p {
          color: #d32f2f;
        }
      }
    `}
`

export const Text = styled.p`
  margin: 0;
  font-size: 14px;
  color: #181d11;
  font-family: Montserrat;
  font-weight: 600;
`

export const HiddenInput = styled.input`
  display: none;
`

export const Button = styled.a`
  color: #181d11;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`

export const FileContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const FileName = styled.p`
  margin: 0;
  color: #626972;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
`

export const ErrorMessage = styled.span`
  display: flex;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  color: #d32f2f;
`
