import { useApiQuery } from "hooks/api/use-api-query";

function useConfigOptions(userType: string) {
  return useApiQuery({
    options: {cacheTime: 0},
    instance: "igreen",
    endpoint: `/companies/${userType}`,
    message: {
      error: "Não foi possível recuperar os dados de opções",
    },
  });
}

export { useConfigOptions };
