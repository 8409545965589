/**
 * Merge two base64 images vertically.
 *
 * @param {string} base64Image1 - Base64 data of the first image.
 * @param {string} base64Image2 - Base64 data of the second image.
 * @param {number | null} quality - Image quality when converting to JPEG (0.0 to 1.0).
 *                                  If null, the default quality of 0.8 will be used.
 * @returns {Promise<string>} A Promise that resolves with the base64 data of the merged image.
 */
export const mergeImages = async (base64Image1: string, base64Image2: string, imageQuality? : number): Promise<string> => {
    return new Promise((resolve, reject) => {
        const img1 = new Image();
        img1.src = 'data:image/png;base64,' + base64Image1;
    
        const img2 = new Image();
        img2.src = 'data:image/png;base64,' + base64Image2;
     
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
     
        img1.onload = () => {
            canvas.width = Math.max(img1.width, img2.width);
            canvas.height = img1.height + img2.height;
     
            context?.drawImage(img1, 0, 0);
    
            img2.onload = () => {
                context?.drawImage(img2, 0, img1.height);
                resolve(canvas.toDataURL('image/jpeg', imageQuality ?? 0.8).split(',')[1]);
            };
    
            img2.onerror = (error) => {
                reject(error);
            };
        };
    
        img1.onerror = (error) => {
            reject(error);
        };
    });
};
