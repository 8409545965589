import * as z from 'zod'

import { useApiMutate } from 'hooks/api'

const paramsSchema = z.object({
  type: z.string().min(1, 'Necessário encaminhar o tipo de documento'),
  file: z.any(),
  powerCompany: z.string().optional(),
  password: z.string().optional().nullable(),
  accountType: z.string().optional(),
  stateConsumption: z.string().optional()
})

const responseSchema = z.any()

type TypeParams = z.infer<typeof paramsSchema>
type TypeResponse = z.infer<typeof responseSchema>

export function usePostAnyDocs() {
  return useApiMutate<TypeParams, TypeResponse>({
    instance: 'igreen',
    method: 'post',
    endpoint: '/analyze-file',
    responseSchema,
    paramsSchema,
  })
}
