import { useEffect } from "react";
import { Banner, Select, Subtitle, TextField, Title } from "components";
import { StartIcon } from "assets/svg";
import { useGlobalData } from "hooks/api";
import {
  Container,
  FormLayout,
  Text,
  HiddenInput
} from "./styles";
import { useFormContext } from "react-hook-form";
import { DUE_DATE_OPTIONS } from "./constants";

export const AccountStateForm = ({
  generateOptions,
}: {
  generateOptions: () => any[];
}) => {
  const { accountType, customer } = useGlobalData();
  const { setValue, watch } = useFormContext();
  const elegibility = customer?.elegibilidade;
  const ufconsumo = watch("ufconsumo");

  useEffect(() => {
    if (!accountType.length) return;
    setValue("accountType", accountType);
  }, []);

  const stateOptions = generateOptions();

  return (
    <Container>
      <Title>Precisamos das informações da sua distribuidora de energia</Title>
      <Subtitle>
        É muito importante que nesta etapa você preencha os campos com atenção,
        é só ter a sua conta de luz mais recente em mãos e se basear a partir
        dela.
      </Subtitle>

      <Banner
        icon={<StartIcon />}
        description="Tudo pronto para começar? esteja com a sua conta de energia atualizada em mãos."
      />

      <FormLayout>
        <Text>
          Em qual estado está instalada sua unidade consumidora e qual o número
          do seu documento?
        </Text>

        <Select
          name="ufconsumo"
          label="Estado"
          options={stateOptions}
          required
          disabled={elegibility}
        />
        {accountType === "PF" ? (
          <TextField
            name="cpf_cnpj"
            label="CPF"
            mask="000.000.000-00"
            required
          />
        ) : (
          <TextField
            name="cnpj"
            label="CNPJ"
            mask="00.000.000/0000-00"
            required
          />
        )}

        {ufconsumo === "MT" && (
          <Select
            name="dtvencimentocontrato"
            label="Data Vencimento Preferida"
            options={DUE_DATE_OPTIONS}
          />
        )}

        <HiddenInput name="accountType" />
      </FormLayout>
    </Container>
  );
};
