import ReactDOM from 'react-dom/client'
import Bugsnag from '@bugsnag/js'
import React from 'react'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { App } from './app'

import './config/react-pdf'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import './index.css'
import './font.css'

Bugsnag.start({
  apiKey: '794cea5a34d484fbaf0b5c1cfca89328',
  plugins: [new BugsnagPluginReact()],
})

const ErrorBoundary =
  Bugsnag?.getPlugin('react')?.createErrorBoundary(React) ?? React.Fragment

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>
)
