import styled from "styled-components";

const Container = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

const MonthsContainer = styled.div`
  display: grid;
  gap: 24px;
`;

const AverageContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const ErrorMessage = styled.span`
  display: flex;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  color: #d32f2f;
`;

export { Container, MonthsContainer, AverageContainer, ErrorMessage };