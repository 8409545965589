import { useEffect, useState } from "react";
import _some from "lodash/some";
import { useFormContext } from "react-hook-form";
import { TextField, Select, Subtitle, Title, Checkbox } from "components";
import { useGlobalData } from "../../../../hooks/api";
import { Container, FormLayout } from "./styles";

const residenceTypeOptions = [
  {
    label: "Residencial",
    value: "Residencial",
  },
  {
    label: "Comercial",
    value: "Comercial",
  },
  {
    label: "Industrial",
    value: "Industrial",
  },
  {
    label: "Rural",
    value: "Rural",
  },
  {
    label: "Serviço Público",
    value: "Serviço Público",
  },
];

const classificationOptions = [
  {
    label: "Monofásico",
    value: "Monofásico",
  },
  {
    label: "Bifásico",
    value: "Bifásico",
  },
  {
    label: "Trifásico",
    value: "Trifásico",
  },
];

export function EnergyProviderForm({ generateOptions }: any) {
  const [options, setOptions] = useState([]);
  const { setValue, watch, getValues, reset, trigger } = useFormContext();
  const { customer } = useGlobalData();
  const powerCompany = watch("concessionaria");
  const [
    concessionariaChecked,
    classificacaoChecked,
    energyBoxChecked,
    valorkwhChecked,
    numclienteChecked,
    valorcontribuicaoChecked,
    numinstalacaoChecked,
  ] = watch([
    "concessionariaChecked",
    "classificacaoChecked",
    "energyBoxChecked",
    "valorkwhChecked",
    "numclienteChecked",
    "valorcontribuicaoChecked",
    "numinstalacaoChecked",
  ]);

  const state: "MG" | "MT" | "MS" | "RJ" | "SP" | "GO" | "PE" =
    watch("ufconsumo");

  useEffect(() => {
    if (!state) return;

    setOptions(generateOptions(state));

    if (customer) {
      reset({
        ...getValues(),
        concessionariaChecked: true,
        classificacaoChecked: true,
        energyBoxChecked: true,
        valorkwhChecked: true,
        numclienteChecked: true,
        valorcontribuicaoChecked: true,
        numinstalacaoChecked: true,
      });
    }
  }, []);

  useEffect(() => {
    if (!options?.length || _some(options, { value: powerCompany })) return;

    setValue("concessionaria", "");
  }, [options]);

  const handleCheck = async ({ name }: { name: string; value: boolean }) => {
    const fieldName = name.replace("Checked", "");
    await trigger(fieldName);
  };

  return (
    <Container>
      <Title>
        Agora precisamos conhecer mais sobre a empresa que lhe fornece energia
        atualmente
      </Title>
      <Subtitle>
        Os dados da sua conta de luz foram preenchidos automaticamente. Por
        favor, revise as informações abaixo e confirme se estão corretas. Caso
        encontre alguma divergência, faça as correções necessárias.
      </Subtitle>
      <FormLayout>
        <Select
          name="concessionaria"
          label="Distribuidora de energia"
          options={options}
          required
          disabled={concessionariaChecked}
        />
        <Checkbox name="concessionariaChecked" onChange={handleCheck} />
      </FormLayout>
      <FormLayout>
        <Select
          name="classificacao"
          label="Selecione o tipo da sua instalação"
          options={residenceTypeOptions}
          required
          disabled={classificacaoChecked}
        />
        <Checkbox name="classificacaoChecked"onChange={handleCheck} />
      </FormLayout>
      <FormLayout>
        <Select
          name="energyBox"
          label="Selecione o tipo do seu padrão"
          options={classificationOptions}
          required
          disabled={energyBoxChecked}
        />
        <Checkbox name="energyBoxChecked" onChange={handleCheck} />
      </FormLayout>
      <FormLayout>
        <TextField
          name="valorkwh"
          label="Valor Kwh"
          mask="0,00"
          required
          disabled={valorkwhChecked}
        />
        <Checkbox name="valorkwhChecked" onChange={handleCheck} />
      </FormLayout>
      <FormLayout>
        <TextField
          name="numcliente"
          label="Número Cliente"
          required
          disabled={numclienteChecked}
        />
        <Checkbox name="numclienteChecked" onChange={handleCheck} />
      </FormLayout>
      <FormLayout>
        <TextField
          name="valorcontribuicao"
          mask={[
            {
              mask: "0,00",
            },
            {
              mask: "00,00",
            },
            {
              mask: "000,00",
            },
          ]}
          label="Taxa Ilum. Pública"
          required
          disabled={valorcontribuicaoChecked}
        />
        <Checkbox name="valorcontribuicaoChecked" onChange={handleCheck} />
      </FormLayout>

      <FormLayout>
        <TextField
          name="numinstalacao"
          label="Número Instalação"
          required
          disabled={numinstalacaoChecked}
        />
        <Checkbox name="numinstalacaoChecked" onChange={handleCheck}/>
      </FormLayout>
    </Container>
  );
}
