export const BuildingIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      stroke="#181D11"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.664 28.005V5.329c0-.737.597-1.334 1.334-1.334h8.003c.737 0 1.334.597 1.334 1.334v22.676M28.004 28.005V10.548c0-.672-.545-1.217-1.217-1.217h-5.452"
    />
    <path
      stroke="#181D11"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M10.665 9.33H5.213c-.673 0-1.218.546-1.218 1.218v17.457M21.335 13.332h3.335M21.335 17.334h3.335M21.335 21.336h3.335M7.33 13.332h3.334M7.33 17.334h3.334M7.33 21.336h3.334M13.999 17.334h4.002M13.999 21.336h4.002M13.999 13.332h4.002M13.999 9.33h4.002M16 25.337v2.668M29.339 28.005H2.66"
    />
  </svg>
);
