import styled from "styled-components";

const Container = styled.div`
  display: flex;
`;

const ErrorMessage = styled.span`
  display: flex;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  color: #d32f2f;
`;

export { Container, ErrorMessage };