export const StartIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#181D11"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5 13h2M5 16h4M16 19.316v.184a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 2 19.5v-15A1.5 1.5 0 0 1 3.5 3h11A1.5 1.5 0 0 1 16 4.5V14M16 9h1.292a3 3 0 0 1 1.341.317l.654.327a3 3 0 0 1 1.155 1.019L22 13"
    />
    <circle
      cx={6.5}
      cy={7.5}
      r={1.5}
      stroke="#181D11"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
    />
    <path
      stroke="#181D11"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m16 14 .002-.003-2.38-2.52a1.518 1.518 0 0 0-2.177-.032v0a1.518 1.518 0 0 0-.191 1.914l2.987 4.494a4 4 0 0 0 2.064 1.58L21 21M17.5 14H16"
    />
  </svg>
)
