import { ReactNode } from "react";
import styles from "./styles.module.scss";

interface SubTitleProps {
	children: ReactNode;
}

export function Subtitle({ children }: SubTitleProps) {
	return <h3 className={styles.subtitle}>{children}</h3>;
}
