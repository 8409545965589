import { ProgressBar } from "components/ProgressBar";
import { Container } from "./UploadDocuments.styles";
import { Banner } from "components/Banner";
import { AttachmentIcon } from "assets";
import { Box, Modal } from "@mui/material";

type UploadDocumentsProgressProps = {
  progress: number;
  onClose?: () => void;
};

const UploadDocumentsProgress: React.FC<UploadDocumentsProgressProps> = ({
  progress,
  onClose,
}) => {
  return (
    <Modal open={true} onClose={onClose}>
      <Box
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80vw",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 8,
        }}
      >
        <Container>
          <Banner
            icon={<AttachmentIcon />}
            description="Estamos processando o envio de seus documentos para nossa base de dados. Por favor, mantenha seu navegador aberto e aguarde a confirmação de conclusão. Agradecemos sua paciência!"
          />
          <ProgressBar progress={progress} />
        </Container>
      </Box>
    </Modal>
  );
};

export { UploadDocumentsProgress };
