import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  position: relative;
`;

export const Text = styled.p`
  margin: 0;
  color: #181d11;
  font-family: "Nexa Bold";
  font-size: 16px;
  font-weight: 700;
`;

export const FileTypeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

export const ImageContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`;

export const ImageHint = styled.span`
  display: flex;
  color: #626972;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;

  span {
    color: #f46d00;
  }
`;

export const FieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FormLayout = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  background-color: #f9f9f9;
  position: absolute;
  width: 102%;
  height: 100%;
  z-index: 1;
`;

export const StandbyMessage = styled.span`
  color: #d32f2f;
  font-family: "Nexa Bold";
`;

export const LoaderTextContainer = styled.div``;
