import * as z from "zod";

export const schemaSocialContract = z
  .object({
    companyFileType: z.string().min(1, "Tipo de arquivo é obrigatório"),
    caminhocontratosocial: z.string().optional(),
  })
  .superRefine((values, ctx) => {
    if (!values.caminhocontratosocial &&
      values.companyFileType === "SOCIAL_CONTRACT"
    ) {
      ctx.addIssue({
        message: "Contrato social é obrigatório",
        code: z.ZodIssueCode.custom,
        path: ["caminhocontratosocial"],
      });
    }

    if (!values.caminhocontratosocial && values.companyFileType === "ATA") {
      ctx.addIssue({
        message: "ATA é obrigatória",
        code: z.ZodIssueCode.custom,
        path: ["caminhocontratosocial"],
      });
    }
  }) as any;
