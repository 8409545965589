import { parse, isValid, differenceInYears } from 'date-fns';

function isValidDate(dateString: string) {
  const parsedDate = parse(dateString, "dd/MM/yyyy", new Date());
  return isValid(parsedDate);
}


function isOver18(dateString: string) {
  if (!isValidDate(dateString)) return false;

  const parsedDate = parse(dateString, "dd/MM/yyyy", new Date());
  const today = new Date();
  const age = differenceInYears(today, parsedDate);

  return age >= 18;
}

export {
  isValidDate,
  isOver18
}