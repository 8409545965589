import { isoDateToString } from 'utils'

type DataRecord = Record<string, any>

export const personalJuridicalFormDefaultValues = ({
  initialData,
  currentData,
}: {
  initialData: DataRecord
  currentData: DataRecord
}) => {
  const getDefaultValue = (key: string) => {

    if (key === 'dtabertura') {
      return currentData[key] || isoDateToString(initialData?.dtabertura) || ''
    }

    return currentData[key] || initialData[key] || ''
  }

  return {
    caminhoarquivocnpj: getDefaultValue('caminhoarquivocnpj'),
    cnpj: getDefaultValue('cnpj'),
    razao: getDefaultValue('razao'),
    fantasia: getDefaultValue('fantasia'),
    dtabertura: getDefaultValue('dtabertura'),
    telefone: getDefaultValue('telefone'),
    naturezajuridica: getDefaultValue('naturezajuridica'),
    nire: getDefaultValue('nire'),
    ie: getDefaultValue('ie'),
  }
}
