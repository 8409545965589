import { useController, useFormContext } from "react-hook-form";
import { InputAdornment, MenuItem } from "@mui/material";

import { MuiTextField } from "./styles";

export interface Option {
  value: string;
  label: string;
}

interface SelectProps {
  name: string;
  label: string;
  placeholder?: string;
  mask?: string;
  required?: boolean;
  startAdornment?: JSX.Element | string;
  options: Option[];
  defaultValue?: string;
  disabled?: boolean;
}

export const Select: React.FC<SelectProps> = ({
  name,
  label,
  placeholder,
  mask,
  required,
  startAdornment,
  options,
  defaultValue = "",
  disabled = false,
  ...props
}) => {
  const { control } = useFormContext();
  const {
    field,
    fieldState: { invalid, isDirty },
    formState: { errors },
  } = useController({ name, control, defaultValue });
  const errorMessage = errors[name]?.message;
  const fieldIsValid = isDirty && !invalid;

  return (
    <MuiTextField
      {...props}
      select
      sx={{
        ".MuiFilledInput-root": {
          border: `1px solid ${fieldIsValid ? "#00b22a" : "#808080"}`,
        },
      }}
      required={required}
      name={name}
      label={label}
      placeholder={placeholder}
      value={field.value}
      onChange={(e) => field.onChange(e.target.value)}
      InputProps={{
        disableUnderline: true,
        inputProps: { mask },
        startAdornment: startAdornment && (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
      }}
      fullWidth
      variant="filled"
      error={!!errors[name]}
      helperText={errorMessage as string}
      disabled={disabled}
    >
      {options?.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </MuiTextField>
  );
};
