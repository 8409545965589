export const SvgBack = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={10}
        fill="none"
        {...props}
    >
        <path
            stroke="#181D11"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1.5}
            d="M1 5h12M5.286 9 1 5M5.286 1 1 5"
        />
    </svg>
)
