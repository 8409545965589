type DataRecord = Record<string, string>;

const uploadPaidDebitsFormDefaultValues = ({
  initialData,
  currentData,
}: {
  initialData: DataRecord;
  currentData: DataRecord;
}) => {
  const getDefaultValue = (key: string) => {
    return currentData[key] || initialData[key] || "";
  };

  return {
    caminhocomprovante: getDefaultValue("caminhocomprovante"),
  };
};

export { uploadPaidDebitsFormDefaultValues };
