import styled, { css } from 'styled-components'
import { TextField } from '@mui/material'

export const MuiTextField = styled(TextField)`
  background-color: transparent;
  font-size: 16px;
  border-radius: 12px;

  .MuiFormLabel-root.MuiInputLabel-root {
    font-family: Montserrat;
    font-size: 16px;
  }

  .MuiFormLabel-asterisk {
    color: #ff0000;
  }

  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 55px;
  }

  .MuiSelect-select {
    background-color: transparent;

    &:focus {
      background-color: transparent;
      border-radius: 12px;
    }
  }

  .MuiSelect-select-MuiInputBase-input-MuiFilledInput-input:focus {
    background-color: transparent;
  }

  .MuiFilledInput-root {
    border: 1px solid #808080;
    border-radius: 12px;
    background-color: transparent;
    font-family: Montserrat;

    &:focus {
      border: 1px solid #00b22a;
      background-color: transparent;
    }

    &:hover {
      border: 1px solid #00b22a;
      ${({ disabled }) =>
        !disabled &&
        css`
          background-color: transparent;
        `};
    }

    ${({ error }) =>
      error &&
      `
        border: 1px solid red !important;    
    `}
  }

  .MuiFilledInput-root.Mui-focused {
    background-color: transparent;
    border: 1px solid #00b22a;

    &:hover {
      background-color: transparent;
    }
  }

  label.Mui-focused {
    color: #0009;
  }
`
