import { Title, Banner, FileUploaderInput } from "components";
import { AttachmentIcon, PdfIcon } from "assets/svg";
import { Container, Text, FieldContainer } from "./styles";
const UploadPaidDebitsForm = () => {
  return (
    <Container>
      <Title>
        Precisamos que você nos envie os comprovantes de pagamento das faturas
        em aberto.
      </Title>

      <Banner
        icon={<AttachmentIcon />}
        description="Ao analisarmos sua conta de energia foi identificado que você possui um ou mais débitos em aberto. Para continuarmos com o seu cadastro precisamos que você nos envie o comprovante do pagamento dos mesmos."
      />

      <FieldContainer>
        <Text>Envie-nos o comprovante da fatura em aberto:</Text>
        <FileUploaderInput
          description="Anexar comprovante de pagamento"
          name="caminhocomprovante"
          icon={<PdfIcon />}
          accept="image/*,.pdf"
        />
      </FieldContainer>
    </Container>
  );
};

export { UploadPaidDebitsForm };
