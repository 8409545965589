type DataRecord = Record<string, any>;

export const addressFormDefaultValues = ({
  initialData,
  currentData,
}: {
  initialData: DataRecord;
  currentData: DataRecord;
}) => {
  const getDefaultValue = (key: string) => currentData[key] || initialData[key] || '';

  return {
    cep: getDefaultValue('cep'),
    complemento: getDefaultValue('complemento'),
    endereco: getDefaultValue('endereco'),
    numero: getDefaultValue('numero'),
    bairro: getDefaultValue('bairro'),
    cidade: getDefaultValue('cidade'),
    uf: getDefaultValue('uf'),
  }
}
