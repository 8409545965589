import { useApiMutate } from "hooks/api";

export function usePostClientInfo() {
	return useApiMutate({
		instance: "igreen",
		method: "post",
		endpoint: "/cliente",
		message: {
			error: "Não foi possível realizar o cadastro.",
		},
	});
}
