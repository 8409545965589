import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Title, FileUploaderInput, Banner } from 'components'
import { DocumentTopIcon, DocumentBottomIcon, AttachmentIcon } from 'assets/svg'
import {
  Container,
  ImageContainer,
  ImageHint,
  FieldContainer,
} from './styles'

const ImageUpload = () => {
  const { watch } = useFormContext()
  const imageFields = watch(['caminhoarquivodoc1', 'caminhoarquivodoc2'])

  useEffect(() => {
    if (imageFields.includes(undefined)) return

    if (imageFields.includes('')) return
  }, [imageFields])

  return (
    <FieldContainer>
      <ImageContainer>
        <FileUploaderInput
          description="Anexar frente"
          name="caminhoarquivodoc1"
          icon={<DocumentTopIcon />}
          accept="image/*"
        />
        <FileUploaderInput
          description="Anexar verso"
          name="caminhoarquivodoc2"
          icon={<DocumentBottomIcon />}
          accept="image/*"
        />
      </ImageContainer>
      <ImageHint>
        Aceitamos formatos em PNG e JPG <span>*</span>
      </ImageHint>
    </FieldContainer>
  )
}

export const UploadPersonalDataForm: React.FC = () => {
  return (
    <Container>
      <Title>Agora você precisa anexar o seu documento com foto CNH ou RG</Title>
      <Banner
        icon={<AttachmentIcon />}
        description="Seu arquivo será processado por uma Inteligência Artificial! Anexe-o e a IA extrairá os dados automaticamente, preenchendo o formulário para você. Rápido, seguro e eficiente."
      />
      <ImageUpload />
    </Container>
  )
}
