import { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { CircularProgress } from '@mui/material';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: 'outline' | 'normal';
  loading?: boolean;
  disabled?: boolean;
}

export function Button({ color = 'normal', loading = false, disabled, ...props }: ButtonProps) {
  return (
    <button
      className={classNames(styles.buttonContainer, {
        [styles[color]]: color,
        [styles.disabled]: loading,
      })}
      disabled={disabled || loading} 
      {...props}
    >
      {loading ? <CircularProgress color='inherit' size={30}/> : props.children}
    </button>
  );
}
