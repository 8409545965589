import axios from 'axios'
import toast from 'react-hot-toast'

import { API_COMERC, API_TOKEN_COMERC } from 'config/env'

export const axiosComerc = axios.create({
  baseURL: API_COMERC,
})

axiosComerc.interceptors.request.use((config: any) => {
  const headers = {
    ...config.headers,
    'Content-Type': 'application/json',
    Authorization: `Bearer ${API_TOKEN_COMERC}`,
  }

  return {
    ...config,
    headers,
  }
})

axiosComerc.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorMessageFromApi = error?.response?.data?.error?.message

    toast.error(errorMessageFromApi || 'Houve um erro em sua solicitação.')
  }
)
