export const AttachmentIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#181D11"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m19.121 6.388-2.509-2.51A3 3 0 0 0 14.491 3H7a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8.51a3 3 0 0 0-.879-2.122v0Z"
      clipRule="evenodd"
    />
    <path
      stroke="#181D11"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12 14v-3.5A1.5 1.5 0 0 1 13.5 9v0a1.5 1.5 0 0 1 1.5 1.5V14a3 3 0 0 1-3 3v0a3 3 0 0 1-3-3v-3"
    />
  </svg>
)