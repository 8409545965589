type DataRecord = Record<string, any>;

export const uploadSocialContractDefaultValues = ({
  initialData,
  currentData,
}: {
  initialData: DataRecord;
  currentData: DataRecord;
}) => {
  const getDefaultValue = (key: string) => {
    return currentData[key] || initialData[key] || "";
  };

  return {
    caminhocontratosocial: getDefaultValue("caminhocontratosocial"),
    companyFileType: getDefaultValue("companyFileType"),
  };
};
