import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Text = styled.p`
  margin: 0;
  color: #181d11;
  font-family: 'Nexa Bold';
  font-size: 16px;
  font-weight: 700;
`

export { Container, Text }