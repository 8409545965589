import { TextFieldProps } from '@mui/material'
import { MuiTextField } from './styles'

export const CustomTextField: React.FC<TextFieldProps> = (props) => {
  return (
    <MuiTextField
      {...props}
      sx={{
        '.MuiFilledInput-root': {
          border: '1px solid #808080',
        },
      }}
    />
  )
}
