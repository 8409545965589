import { StepByStepForm } from "components";
import { AxiosError } from "axios";
import { SubmitHandler } from "react-hook-form";
import {
  AccountStateForm,
  AddressForm,
  PersonalInfoForm,
  PersonalJuridicalForm,
  UploadEnergyBillForm,
  EnergyProviderForm,
  CompanyAddressForm,
  EnergyCalcForm,
  UploadSocialContractForm,
  UploadPersonalDataForm,
  schemaAddressData,
  schemaPersonalInfo,
  schemaAccountState,
  schemaEnergyBill,
  schemaPersonalJuridical,
  schemaEnergyProviderData,
  schemaCompanyAddressData,
  getSchemaEnergyCalcData,
  schemaSocialContract,
  schemaPersonalData,
  accountStateFormDefaultValues,
  uploadEnergyBillFormDefaultValues,
  energyProviderFormDefaultValues,
  energyCalcFormDefaultValues,
  personalInfoFormDefaultValues,
  addressFormDefaultValues,
  personalJuridicalFormDefaultValues,
  companyAddressFormDefaultValues,
  uploadSocialContractDefaultValues,
  uploadPersonalDataFormDefaultValues,
  schemaPaymentVoucher,
  UploadPaidDebitsForm,
  uploadPaidDebitsFormDefaultValues
} from "./components";
import {
  usePatchClientInfo,
  usePostClientInfo,
  usePostUploadFiles,
} from "./api";
import { getFileExtensionFromBase64, getObjectsDifference } from "utils";
import { useGlobalData } from "hooks/api";
import { useConfigOptions } from "./hooks/useConfigOptions";
import {
  FILE_FIELDS,
  FILE_NAMES_S3,
  generateConfigOptions,
  prepareFormData,
  prepareFormDataToUpdate,
} from "./ConnectionForm.helpers";
import { ConfigOptionResponse, FormCommonProps } from "./ConnectionForm.types";
import { useMemo } from "react";

const stepsPFConfig = (pendingDebts: boolean, customer: any) => [
  {
    title: "Distribuidora de energia",
    component: ({ configOptions }: FormCommonProps) => (
      <AccountStateForm generateOptions={configOptions.getStateOptions} />
    ),
    schema: () => schemaAccountState,
    defaultValues: accountStateFormDefaultValues,
  },
  {
    title: "Anexar conta de luz",
    component: ({ configOptions }: FormCommonProps) => (
      <UploadEnergyBillForm
        generateOptions={configOptions.getCompanyOptionsByState}
      />
    ),
    schema: () => schemaEnergyBill,
    defaultValues: uploadEnergyBillFormDefaultValues,
  },
  ...(pendingDebts || customer
    ? [
        {
          title: "Débitos abertos",
          component: () => <UploadPaidDebitsForm />,
          schema: () => schemaPaymentVoucher(customer),
          defaultValues: uploadPaidDebitsFormDefaultValues,
        },
      ]
    : []),
  {
    title: "Documento com foto",
    component: () => <UploadPersonalDataForm />,
    schema: () => schemaPersonalData,
    defaultValues: uploadPersonalDataFormDefaultValues,
  },
  {
    title: "Dados de sua empresa de energia",
    component: ({ configOptions }: FormCommonProps) => (
      <EnergyProviderForm
        generateOptions={configOptions.getCompanyOptionsByState}
      />
    ),
    schema: () => schemaEnergyProviderData,
    defaultValues: energyProviderFormDefaultValues,
  },
  {
    title: "Consumo de energia",
    component: ({ configOptions }: FormCommonProps) => (
      <EnergyCalcForm
        generateRangeValues={configOptions.getPowerLevelRangeByStateAndCompany}
        generateDiscountOptions={configOptions.getDiscountOptions}
      />
    ),
    schema: getSchemaEnergyCalcData,
    defaultValues: energyCalcFormDefaultValues,
  },
  {
    title: "Dados pessoais",
    component: () => <PersonalInfoForm />,
    schema: () => schemaPersonalInfo,
    defaultValues: personalInfoFormDefaultValues,
  },
  {
    title: "Endereço",
    component: () => <AddressForm />,
    schema: () => schemaAddressData,
    defaultValues: addressFormDefaultValues,
  },
];

const stepsPJConfig = (pendingDebts: boolean, customer: any) => [
  ...stepsPFConfig(pendingDebts, customer),
  {
    title: "Documentos da Empresa",
    component: () => <UploadSocialContractForm />,
    schema: () => schemaSocialContract,
    defaultValues: uploadSocialContractDefaultValues,
  },
  {
    title: "Dados da Empresa",
    component: () => <PersonalJuridicalForm />,
    schema: () => schemaPersonalJuridical,
    defaultValues: personalJuridicalFormDefaultValues,
  },
  {
    title: "Endereço da empresa",
    component: () => <CompanyAddressForm />,
    schema: () => schemaCompanyAddressData,
    defaultValues: companyAddressFormDefaultValues,
  },
];

const getFilesFromForm = (
  formData: Record<string, string>,
  mutateData: Record<string, string>
) => {
  const files: Array<{ file: string; fileName: string }> = FILE_FIELDS.reduce<
    Array<{ file: string; fileName: string }>
  >((acc, field) => {
    if (!formData[field] || !formData[field].includes("base64,")) return acc;
    const file = formData[field]?.split("base64,")[1];
    const extension = getFileExtensionFromBase64(file);
    const fileName = `${mutateData.idcliente}-${FILE_NAMES_S3[field]}.${extension}`;

    acc.push({ file, fileName });
    return acc;
  }, []);

  return files;
};

interface ConnectionFormProps {
  sponsorId: string;
  indcli: string;
  onSubmit(): void;
  onGoStartPage(): void;
  onError: (err: AxiosError) => void;
}

export function ConnectionForm({
  sponsorId,
  indcli,
  onSubmit,
  onGoStartPage,
  onError,
}: ConnectionFormProps) {
  const {
    accountType,
    billElegibility,
    customer,
    setIsLoading,
    energyBillAnalysisResult,
  } = useGlobalData();
  const { mutate: onSubmitForm } = usePostClientInfo();
  const { mutate: onEditCustomer } = usePatchClientInfo(customer?.idcliente);
  const { mutate: onUploadFiles } = usePostUploadFiles();
  const pendingDebts = energyBillAnalysisResult?.openDebits || false;
  const hasPlates = energyBillAnalysisResult?.possuiPlacas;
  const icmsDiscount = energyBillAnalysisResult?.descontoIcms;

  const handleSuccess = async (
    formData: Record<string, string>,
    mutateData: Record<string, string>
  ) => {
    const files = getFilesFromForm(formData, mutateData);
    onUploadFiles(
      { files },
      {
        onSuccess: onSubmit,
      }
    );
  };

  const createCustomer = (formData: Record<string, string>) => {
    const elegibility = billElegibility;
    try {
      const updatedData = prepareFormData(
        formData,
        sponsorId,
        indcli,
        elegibility,
        hasPlates,
        icmsDiscount,
      );

      onSubmitForm(updatedData, {
        onSuccess: (data: Record<string, string>) =>
          handleSuccess(formData, data),
        onError: (err) => onError(err as AxiosError),
      });
    } catch (error) {
      console.error("An error occurred:", error);
      setIsLoading(false);
    }
  };

  const editCustomer = (formData: Record<string, string>) => {
    const elegibility = billElegibility;

    try {
      const normalizedData = prepareFormDataToUpdate(
        formData,
        elegibility,
        hasPlates,
        icmsDiscount
      );
      const dataToUpdate = getObjectsDifference(normalizedData, customer);

      onEditCustomer(
        {
          ...dataToUpdate,
          cpf_cnpj: formData.cpf_cnpj,
          elegibilidade: Boolean(dataToUpdate.elegibilidade),
        },
        {
          onSuccess: () => handleSuccess(formData, customer),
          onError: (err) => onError(err as AxiosError),
        }
      );
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleSubmitForm: SubmitHandler<Record<string, string>> = (
    formData
  ) => {
    if (customer) return editCustomer(formData);

    return createCustomer(formData);
  };

  const stepsConfig = useMemo(() => {
    return accountType === "PF"
      ? stepsPFConfig(pendingDebts, customer)
      : stepsPJConfig(pendingDebts, customer);
  }, [pendingDebts, accountType, customer]);
  
  const optionsData: ConfigOptionResponse = useConfigOptions(accountType);

  const configOptions = generateConfigOptions(optionsData.data ?? []);

  return (
    <StepByStepForm
      configOptions={configOptions}
      stepsConfig={stepsConfig}
      onSubmit={handleSubmitForm}
      onGoStartPage={onGoStartPage}
    />
  );
}
