type DataRecord = Record<string, any>

export const uploadPersonalDataFormDefaultValues = ({
  initialData,
  currentData,
}: {
  initialData: DataRecord
  currentData: DataRecord
}) => {
  const getDefaultValue = (key: string) => {
    return currentData[key] || initialData[key] || ''
  }

  return {
    caminhoarquivodoc1: getDefaultValue('caminhoarquivodoc1'),
    caminhoarquivodoc2: getDefaultValue('caminhoarquivodoc2'),
  }
}
