type DataRecord = Record<string, any>;

export const companyAddressFormDefaultValues = ({
  initialData,
  currentData,
}: {
  initialData: DataRecord;
  currentData: DataRecord;
}) => {
  const getDefaultValue = (key: string) => currentData[key] || initialData[key] || '';
  
  return {
    emp_cep: getDefaultValue('emp_cep'),
    emp_complemento: getDefaultValue('emp_complemento'),
    emp_endereco: getDefaultValue('emp_endereco'),
    emp_numero: getDefaultValue('emp_numero'),
    emp_bairro: getDefaultValue('emp_bairro'),
    emp_cidade: getDefaultValue('emp_cidade'),
    emp_uf: getDefaultValue('emp_uf'),
  }
}
