import * as z from "zod";
import { isValidCpf, isValidCnpj } from "utils";
import _isEmpty from "lodash/isEmpty";

export const schemaAccountState = z
  .object({
    ufconsumo: z
      .string({ required_error: "Estado é obrigatório" })
      .min(1, "Estado é obrigatório"),
    accountType: z.string().optional(),
    cpf_cnpj: z.string().optional(),
    cnpj: z.string().optional(),
    dtvencimentocontrato: z.string().optional(),
  })
  .superRefine((values, ctx) => {
    if (values.ufconsumo === "MT" && _isEmpty(values.dtvencimentocontrato))
      ctx.addIssue({
        message: "Data de vencimento preferida é obrigatório",
        code: z.ZodIssueCode.custom,
        path: ["dtvencimentocontrato"],
      });

    if (values.accountType === "PF") {
      if (!values.cpf_cnpj?.length) {
        ctx.addIssue({
          message: "CPF é obrigatório",
          code: z.ZodIssueCode.custom,
          path: ["cpf_cnpj"],
        });
      }

      if (!isValidCpf(values.cpf_cnpj as string)) {
        ctx.addIssue({
          message: "CPF é inválido",
          code: z.ZodIssueCode.custom,
          path: ["cpf_cnpj"],
        });
      }

      return;
    }

    if (!values.cnpj?.length) {
      ctx.addIssue({
        message: "CNPJ é obrigatório",
        code: z.ZodIssueCode.custom,
        path: ["cnpj"],
      });
    }

    if (!isValidCnpj(values.cnpj as string)) {
      ctx.addIssue({
        message: "CNPJ é inválido",
        code: z.ZodIssueCode.custom,
        path: ["cnpj"],
      });
    }
  }) as any;
