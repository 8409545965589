import { useEffect } from 'react';
import { Checkbox, Select, TextField, Title } from 'components'
import { Container, FormLayout, FormLayoutInline } from './styles'
import { states } from '../address-form/address-form.constants'
import { useGlobalData } from 'hooks';
import { useFormContext } from 'react-hook-form';

export function CompanyAddressForm() {
  const { customer } = useGlobalData();
  const { getValues, reset, trigger } = useFormContext();

  useEffect(() => {
    if (customer) {
      reset({
        ...getValues(),
        emp_cepChecked: true,
        emp_complementoChecked: true,
        emp_enderecoChecked: true,
        emp_numeroChecked: true,
        emp_bairroChecked: true,
        emp_cidadeChecked: true,
        emp_ufChecked: true,
      });
    }
  }, []);

  const handleCheck = async ({ name }: { name: string; value: boolean }) => {
    const fieldName = name.replace("Checked", "");
    await trigger(fieldName);
  };

  return (
    <Container>
      <Title>
        Certo para finalizar agora nos diga qual é o seu endereço da sua
        empresa.
      </Title>

      <FormLayout>
        <TextField name="emp_cep" label="CEP" mask="00.000-000" required />
        <Checkbox name="emp_cepChecked" onChange={handleCheck} />
      </FormLayout>
      <FormLayout>
        <TextField name="emp_endereco" label="Endereço" required />
        <Checkbox name="emp_enderecoChecked" onChange={handleCheck} />
      </FormLayout>
      <FormLayoutInline>
        <FormLayout>
          <TextField name="emp_numero" label="Número" required />
          <Checkbox name="emp_numeroChecked" onChange={handleCheck} />
        </FormLayout>
        <FormLayout>
          <TextField name="emp_complemento" label="Complemento" />
          <Checkbox name="emp_complementoChecked" onChange={handleCheck} />
        </FormLayout>
      </FormLayoutInline>
      <FormLayout>
        <TextField name="emp_bairro" label="Bairro" required />
        <Checkbox name="emp_bairroChecked" onChange={handleCheck} />
      </FormLayout>
      <FormLayout>
        <Select options={states} name="emp_uf" label="Estado" required />
        <Checkbox name="emp_ufChecked" onChange={handleCheck} />
      </FormLayout>
      <FormLayout>
        <TextField name="emp_cidade" label="Cidade" required />
        <Checkbox name="emp_cidadeChecked" onChange={handleCheck} />
      </FormLayout>
    </Container>
  );
}
