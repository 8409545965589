import styled, { css } from 'styled-components'
import * as z from 'zod'

import styles from './styles.module.scss'

interface StepByStepProps {
  currentIndex: number
  steps: {
    component: () => JSX.Element
    schema?: z.ZodTypeAny
  }[]
}

const ProgressBar = styled.div<{ currentStep: number; stepsLength?: number }>`
  ${({ currentStep }) => css`
    width: ${(currentStep || 0) * 104}px;
  `}
`

export function StepByStep({ currentIndex, steps }: StepByStepProps) {
  return (
    <div className={styles.control}>
      <div className={styles.whiteLine}/>

      <ProgressBar currentStep={currentIndex} className={styles.progressBar}/>

      {steps.map((_step, index) =>
          <span
              className={`${index <= currentIndex ? styles.greenStep : undefined}`}
              key={index}
          />)}
    </div>
  )
}
