import { Divider } from "@mui/material";
import { Container, Text, RoundedBackground, Content } from "./styles";

interface BannerProps {
  icon?: JSX.Element | boolean;
  description: string;
  color?: "#00B22A" | "#e9eef2";
}

export const Banner: React.FC<BannerProps> = ({
  icon,
  description,
  color = "#e9eef2",
}) => {
  return (
    <Container>
      <Divider />
      <Content>
        {icon && (
          <RoundedBackground $backgroundColor={color}>{icon}</RoundedBackground>
        )}
        <Text>{description}</Text>
      </Content>
      <Divider />
    </Container>
  );
};
