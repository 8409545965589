import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { Checkbox, Select, TextField, Title } from "components";
import { useGetCepInfo } from "./api";

import { Container, FormLayout, FormLayoutInline } from "./styles";
import { useGlobalData } from "hooks";
import { states } from "./address-form.constants";

export function AddressForm() {
  const [showForm, setShowForm] = useState<boolean>(false);
  const { customer } = useGlobalData();
  const { watch, reset, getValues, trigger } = useFormContext();
  const formData = watch(["nome", "cep", "endereco"]);
  const firstName = formData[0]?.split(" ")[0];
  const cep = formData[1];
  const { data } = useGetCepInfo({ cep });

  useEffect(() => {
    if (customer) {
      reset({
        ...getValues(),
        cepChecked: true,
        complementoChecked: true,
        enderecoChecked: true,
        numeroChecked: true,
        bairroChecked: true,
        cidadeChecked: true,
        ufChecked: true,
      });
    }
  }, []);

  useEffect(() => {
    if (!data) return;

    const formattedCep = cep?.replace(/[^0-9]/g, "") || "";

    if (formattedCep.length < 8) return;

    reset({
      ...getValues(),
      endereco: data.logradouro,
      bairro: data.bairro,
      cidade: data.cidade,
      uf: data.uf,
    });

    setShowForm(true);
  }, [data.logradouro, data.bairro, data.cidade, data.uf, cep, customer]);

  const handleCheck = async ({ name }: { name: string; value: boolean }) => {
    const fieldName = name.replace("Checked", "");
    await trigger(fieldName);
  };

  return (
    <Container>
      <Title>{`Certo ${firstName}, agora nos diga qual é o seu endereço`}</Title>

      <FormLayout>
        <TextField name="cep" label="CEP" mask="00.000-000" required />
        <Checkbox name="cepChecked" onChange={handleCheck} />
      </FormLayout>

      {showForm && (
        <>
          <FormLayout>
            <TextField name="endereco" label="Logradouro" required />
            <Checkbox name="enderecoChecked" onChange={handleCheck} />
          </FormLayout>
          <FormLayoutInline>
            <FormLayout>
              <TextField name="numero" label="Número" required />
              <Checkbox name="numeroChecked" onChange={handleCheck} />
            </FormLayout>
            <FormLayout>
              <TextField name="complemento" label="Complemento" />
              <Checkbox name="complementoChecked" onChange={handleCheck} />
            </FormLayout>
          </FormLayoutInline>
          <FormLayout>
            <TextField name="bairro" label="Bairro" required />
            <Checkbox name="bairroChecked" onChange={handleCheck} />
          </FormLayout>
          <FormLayout>
            <Select options={states} name="uf" label="Estado" required />
            <Checkbox name="ufChecked" onChange={handleCheck} />
          </FormLayout>
          <FormLayout>
            <TextField name="cidade" label="Cidade" required />
            <Checkbox name="cidadeChecked" onChange={handleCheck} />
          </FormLayout>
        </>
      )}
    </Container>
  );
}
