import * as z from "zod";

import { lessUnderscore } from "utils";

export const schemaCompanyAddressData = z.object({
  emp_cep: z.preprocess(
    lessUnderscore,
    z.string().length(10, "CEP Obrigatório")
  ),
  emp_complemento: z.string().optional(),
  emp_endereco: z.string().min(3, "Endereço obrigatório"),
  emp_numero: z.string().min(1, "Número obrigatório"),
  emp_bairro: z.string().min(1, "Bairro obrigatório"),
  emp_cidade: z.string().min(3, "Cidade obrigatória"),
  emp_uf: z.string().length(2, "UF obrigatório"),
  emp_cepChecked: z.boolean().default(false),
  emp_complementoChecked: z.boolean().default(false),
  emp_enderecoChecked: z.boolean().default(false),
  emp_numeroChecked: z.boolean().default(false),
  emp_bairroChecked: z.boolean().default(false),
  emp_cidadeChecked: z.boolean().default(false),
  emp_ufChecked: z.boolean().default(false),
}).superRefine((values, ctx) => {
  if (!!values.emp_cep.length && !values.emp_cepChecked) {
    ctx.addIssue({
      message: "Confirme o número de CEP",
      code: z.ZodIssueCode.custom,
      path: ["emp_cep"],
    });
  }

  if (!!values.emp_endereco.length && !values.emp_enderecoChecked) {
    ctx.addIssue({
      message: "Confirme o endereço",
      code: z.ZodIssueCode.custom,
      path: ["emp_endereco"],
    });
  }

  if (!!values.emp_complemento?.length && !values.emp_complementoChecked) {
    ctx.addIssue({
      message: "Confirme o complemento",
      code: z.ZodIssueCode.custom,
      path: ["emp_complemento"],
    });
  }

  if (!!values.emp_numero.length && !values.emp_numeroChecked) {
    ctx.addIssue({
      message: "Confirme o número",
      code: z.ZodIssueCode.custom,
      path: ["emp_numero"],
    });
  }

  if (!!values.emp_bairro.length && !values.emp_bairroChecked) {
    ctx.addIssue({
      message: "Confirme o bairro",
      code: z.ZodIssueCode.custom,
      path: ["emp_bairro"],
    });
  }

  if (!!values.emp_uf.length && !values.emp_ufChecked) {
    ctx.addIssue({
      message: "Confirme a UF",
      code: z.ZodIssueCode.custom,
      path: ["emp_uf"],
    });
  }

  if (!!values.emp_cidade.length && !values.emp_cidadeChecked) {
    ctx.addIssue({
      message: "Confirme a cidade",
      code: z.ZodIssueCode.custom,
      path: ["emp_cidade"],
    });
  }
});
