import * as z from "zod";
import { useApiMutate, useGlobalData } from "hooks/api";

const paramsSchema = z.object({
  files: z.array(z.object({ file: z.string(), fileName: z.string() })),
});

const responseSchema = z.any();

type TypeParams = z.infer<typeof paramsSchema>;
type TypeResponse = z.infer<typeof responseSchema>;

export function usePostUploadFiles() {
  const { setDocumentsProgress } = useGlobalData()

  return useApiMutate<TypeParams, TypeResponse>({
    instance: "igreen",
    method: "post",
    endpoint: "/files/upload",
    responseSchema,
    paramsSchema,
    onUploadProgress: setDocumentsProgress,
  });
}
