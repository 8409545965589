import { ReactNode } from "react";
import { Button } from "@mui/material";
import styles from "./styles.module.scss";

interface BoxComponentProps {
  children: ReactNode;
  icon: ReactNode;
  onClick: () => void;
}

export function BoxComponent({ onClick, children, icon }: BoxComponentProps) {
  return (
    <div className={styles.boxComponentContainer}>
      {icon}
      <Button
        sx={{
          "&:hover": {
            color: "#ffffff",
            background: "#00b22a",
          },
        }}
        onClick={onClick}
      >
        {children}
      </Button>
    </div>
  );
}
