import { Toaster } from 'react-hot-toast'
import { QueryProvider } from './config/query'
import { RouterProvider } from './config/routes'
import { GlobalDataProvider } from 'context'

export function App() {
  return (
    <GlobalDataProvider>
      <QueryProvider>
        <RouterProvider />
      </QueryProvider>

      <Toaster position="top-right" />
    </GlobalDataProvider>
  )
}
