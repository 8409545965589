import classNames from 'classnames'

import { imagePigCoin, imageBackground, SvgLogo } from 'assets'

import styles from '../../styles.module.scss'

export function ImageForm() {
  return (
    <div className={styles.imageFormContainer}>
      <div>
        <img
          src={imageBackground}
          alt="Image"
          className={classNames(styles.imgCoverFull, styles.imgHeightFull)}
        />
        <img
          src={imagePigCoin}
          alt="Image"
          className={classNames(styles.imgCoverFull)}
        />
      </div>
      <div className={styles.positionedTopLeft}>
        <SvgLogo />
      </div>
    </div>
  )
}
