import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const FormLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
`;

export const FormLayoutInline = styled(Container)`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Text = styled.p`
  margin: 0;
  color: #181d11;
  font-family: "Nexa Bold";
  font-size: 16px;
  font-weight: 700;
`;