import { useQuery } from "@tanstack/react-query";
import { axiosInstances, TypeDefaultApiParams } from "./shared";
import toast from "react-hot-toast";

export const useApiQuery = <Response>({
  instance,
  endpoint,
  responseSchema,
  message,
  queryKey = [],
  options = {}
}: TypeDefaultApiParams) => {
  const apiRequest = () =>
    axiosInstances[instance]
      .get<Response>(endpoint)
      .then((data) => data.data)
      .then((data) => {
        if (!responseSchema) return data;

        const safeData = responseSchema.safeParse(data);

        if (safeData.success) return safeData.data;

        toast.error(message?.error || "Houve um erro nos dados recebidos.");

        return data;
      });

  return useQuery(queryKey, apiRequest, options)
}
