export function formatName(name: string): string {
  const words: string[] = name.split(" ");

  const firstName: string = words[0];

  const lastNome: string = words[words.length - 1];

  const middleNames: string[] = words.slice(1, -1);

  const initialsMiddle: string[] = middleNames.map((name) => name[0]);
  return `${firstName} ${initialsMiddle.join(" ")} ${lastNome}`;
}
