import { MouseEvent, useEffect } from "react";
import toast from "react-hot-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { LinearProgress } from "@mui/material";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { Button, Form } from "components";
import { SvgBack } from "assets";
import { ImageForm } from "./components";
import { UploadDocumentsProgress } from "./components/UploadDocumentsProgress";

import styles from "./styles.module.scss";
import { useGlobalData } from "hooks";
import { usePostAnyDocs } from "features/connection-form/api/post-AnyDocs";
import { compareTwoStrings } from "string-similarity";
import { isValidCpf } from "utils";
import { mergeImages } from "utils/mergeImages";
import { POWER_COMPANIES_WITH_PROMPTS } from "features/connection-form/components/upload-energy-bill-form/constants";

interface StepByStepFormProps {
  stepsConfig: {
    title?: string;
    component: (props: any) => JSX.Element;
    schema: (params: any) => z.ZodSchema;
    defaultValues: (data: any) => any;
  }[];
  onSubmit(data: unknown): void;
  onGoStartPage: () => void;
  configOptions: Record<any, any>;
}

export function StepByStepForm({
  stepsConfig,
  configOptions,
  onSubmit,
  onGoStartPage,
}: StepByStepFormProps) {
  const {
    customer,
    accountType,
    isLoading,
    setIsLoading,
    currentStep,
    setCurrentStep,
    documentsProgress,
  } = useGlobalData();
  const isLastStep = currentStep === stepsConfig.length - 1;
  const showBackButton = !(customer && currentStep === 0);

  const formProps = useForm({
    resolver: zodResolver(stepsConfig[currentStep].schema(configOptions)),
    mode: "onSubmit",
    reValidateMode: "onChange",
    shouldUnregister: false,
  });

  const { handleSubmit, getValues, reset, watch } = formProps;

  const { mutate: onAnalyzeDocs } = usePostAnyDocs();

  useEffect(() => {
    const fillFormWithExistentData = () => {
      reset(
        {
          ...getValues(),
          ...stepsConfig[currentStep].defaultValues({
            initialData: customer,
            currentData: getValues(),
          }),
        },
        { keepDirty: true, keepDefaultValues: true }
      );
    };

    if (!customer) return;

    fillFormWithExistentData();
  }, [currentStep]);

  const goToNextStep = (event?: MouseEvent<HTMLElement>) => {
    event?.preventDefault();

    setCurrentStep((state) => state + 1);

    window.scrollTo(0, 0);
  };

  const handleSubmitForm = () => {
    const shouldGoToNextStep = currentStep < stepsConfig.length - 1;

    const uploadPersonalDataStep =
      stepsConfig[currentStep].title === "Documento com foto";

    if (uploadPersonalDataStep) {
      return handleAnalyzePersonalDocs();
    }

    if (shouldGoToNextStep) {
      return goToNextStep();
    }

    setIsLoading(true);
    onSubmit(getValues());
  };

  const handleBackFormStep = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();

    if (currentStep === 0) {
      onGoStartPage();
      return;
    }

    setCurrentStep((state) => state - 1);
  };

  const handleAnalyzePersonalDocs = async () => {
    const pathPersonalDocuments = ["caminhoarquivodoc1", "caminhoarquivodoc2"];

    const imageFields = watch(pathPersonalDocuments);

    const isDocumentFromCustomer = pathPersonalDocuments.some(
      (field, index) => customer && customer[field] === imageFields[index]
    );

    if (isDocumentFromCustomer) return goToNextStep();

    const personalName = watch("nome");
    const stateOfConsumption = watch("ufconsumo");
    const powerCompany = watch("concessionaria");
    const isStateOfConsumptionEnableForAnalysis =
      POWER_COMPANIES_WITH_PROMPTS.includes(
        `${powerCompany}_${stateOfConsumption}`
      );

    const resetImagesValues = () => {
      reset({
        ...getValues(),
        caminhoarquivodoc1: "",
        caminhoarquivodoc2: "",
      });
    };

    const file = await mergeImages(
      imageFields[0].split("base64,")[1],
      imageFields[1].split("base64,")[1],
      0.95
    );

    setIsLoading(true);
    onAnalyzeDocs(
      {
        type: "cnh",
        file,
      },
      {
        onSuccess(data) {
          if (isStateOfConsumptionEnableForAnalysis && accountType === "PF") {
            const nameToCompare = personalName?.toLowerCase() ?? customer.nome;
            const isSimilarNames =
              compareTwoStrings(data?.nome?.toLowerCase(), nameToCompare) >=
              0.4;

            if (!isSimilarNames) {
              toast.error(
                "O documento anexado possui nome divergente do preenchido na conta de luz.",
                {
                  position: "bottom-center",
                  duration: 10000,
                }
              );
              return resetImagesValues();
            }
          }

          if (!data?.cpf_cnpj || !isValidCpf(data?.cpf_cnpj))
            delete data["cpf_cnpj"];

          reset({
            ...getValues(),
            ...data,
            rg: stateOfConsumption !== "GO" ? data.rg : "",
            emissor: stateOfConsumption !== "GO" ? data.emissor : "",
            nacionalidade: data?.nacionalidade?.toUpperCase().includes("BRASIL")
              ? "Brasileira(o)"
              : "Estrangeira(o)",
          });

          toast.success("Documento analisado com sucesso!");
          goToNextStep();
        },
        onError() {
          resetImagesValues();
          toast.error(
            "Arquivos inválidos. Certifique-se que anexou documentos válidos e legíveis. Tente novamente!",
            {
              duration: 10000,
            }
          );
        },
        onSettled() {
          setIsLoading(false);
        },
      }
    );
  };

  const FormToRender = stepsConfig[currentStep].component;

  const stateOfConsumption = watch("ufconsumo");
  const powerCompany = watch("concessionaria");

  const disableFormButton =
    documentsProgress > 0 ||
    (stateOfConsumption === "SP" && powerCompany === "EDP");

  return (
    <div className={styles.mainContainer}>
      <ImageForm />

      <div className={styles.contentContainer}>
        <ProgressBar
          title={stepsConfig[currentStep]?.title}
          currentStep={currentStep + 1}
          maxStep={stepsConfig.length}
        />

        {showBackButton && <GoBackButton onGoBack={handleBackFormStep} />}

        <Form
          {...formProps}
          className={styles.formContainer}
          onSubmit={handleSubmit(handleSubmitForm)}
        >
          <FormToRender configOptions={configOptions} />

          {isLastStep && !!documentsProgress && (
            <UploadDocumentsProgress progress={documentsProgress} />
          )}

          <div className={styles.buttonContainer}>
            <Button
              type="submit"
              loading={isLoading}
              disabled={disableFormButton}
            >
              {isLastStep ? "Finalizar" : "Continuar"}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

function ProgressBar({
  currentStep,
  maxStep,
  title,
}: {
  currentStep: number;
  maxStep: number;
  title?: string;
}) {
  return (
    <div className={styles.progressBarContainer}>
      <div>
        <span>{title}</span>

        <span>
          Etapa {currentStep} de {maxStep}
        </span>
      </div>

      <LinearProgress
        variant="determinate"
        value={(currentStep / maxStep) * 100}
        color="inherit"
      />
    </div>
  );
}

function GoBackButton({
  onGoBack,
}: {
  onGoBack(event: MouseEvent<HTMLElement>): void;
}) {
  return (
    <button className={styles.goBackButtonContainer} onClick={onGoBack}>
      <div className={styles.goBackButton}>
        <SvgBack />
      </div>

      <span>Voltar</span>
    </button>
  );
}
