const naturezaJuridica = [
  {
    id: "ASSOCIAÇÃO PRIVADA",
    label: "Associação Privada",
  },
  {
    id: "AUTARQUIA ESTADUAL OU DO DISTRITO FEDERAL",
    label: "Autarquia Estadual ou do Distrito Federal",
  },
  {
    id: "AUTARQUIA FEDERAL",
    label: "Autarquia Federal",
  },
  {
    id: "AUTARQUIA MUNICIPAL",
    label: "Autarquia Municipal",
  },
  {
    id: "CANDIDATO A CARGO POLÍTICO ELETIVO",
    label: "Candidato a Cargo Político Eletivo",
  },
  {
    id: "CLUBE/FUNDO DE INVESTIMENTO",
    label: "Clube/Fundo de Investimento",
  },
  {
    id: "COMISSÃO DE CONCILIAÇÃO PRÉVIA",
    label: "Comissão de Conciliação Prévia",
  },
  {
    id: "COMISSÃO POLINACIONAL",
    label: "Comissão Polinacional",
  },
  {
    id: "COMITÊ FINANCEIRO DE PARTIDO POLÍTICO",
    label: "Comitê Financeiro de Partido Político",
  },
  {
    id: "COMUNIDADE INDÍGENA",
    label: "Comunidade Indígena",
  },
  {
    id: "CONDOMÍNIO EDILÍCIO",
    label: "Condomínio Edilício",
  },
  {
    id: "CONSÓRCIO DE EMPREGADORES",
    label: "Consórcio de Empregadores",
  },
  {
    id: "CONSÓRCIO DE SOCIEDADES",
    label: "Consórcio de Sociedades",
  },
  {
    id: "CONSÓRCIO PÚBLICO DE DIREITO PRIVADO",
    label: "Consórcio Público de Direito Privado",
  },
  {
    id: "CONSÓRCIO PÚBLICO DE DIREITO PÚBLICO (ASSOCIAÇÃO PÚBLICA)",
    label: "Consórcio Público de Direito Público (Associação Pública)",
  },
  {
    id: "CONSÓRCIO SIMPLES",
    label: "Consórcio Simples",
  },
  {
    id: "CONTRIBUINTE INDIVIDUAL",
    label: "Contribuinte Individual",
  },
  {
    id: "COOPERATIVA",
    label: "Cooperativa",
  },
  {
    id: "COOPERATIVAS DE CONSUMO",
    label: "Cooperativas de Consumo",
  },
  {
    id: "DEMAIS CONDOMÍNIOS",
    label: "Demais Condomínios",
  },
  {
    id: "EMPRESA BINACIONAL",
    label: "Empresa Binacional",
  },
  {
    id: "EMPRESA DOMICILIADA NO EXTERIOR",
    label: "Empresa Domiciliada no Exterior",
  },
  {
    id: "EMPRESA INDIVIDUAL DE RESPONSABILIDADE LIMITADA (DE NATUREZA EMPRESÁRIA)",
    label:
      "Empresa Individual de Responsabilidade Limitada (de Natureza Empresária)",
  },
  {
    id: "EMPRESA INDIVIDUAL DE RESPONSABILIDADE LIMITADA (DE NATUREZA SIMPLES)",
    label:
      "Empresa Individual de Responsabilidade Limitada (de Natureza Simples)",
  },
  {
    id: "EMPRESA INDIVIDUAL IMOBILIÁRIA",
    label: "Empresa Individual Imobiliária",
  },
  {
    id: "EMPRESA PÚBLICA",
    label: "Empresa Pública",
  },
  {
    id: "EMPRESÁRIO (INDIVIDUAL)",
    label: "Empresário (Individual)",
  },
  {
    id: "ENTIDADE DE MEDIAÇÃO E ARBITRAGEM",
    label: "Entidade de Mediação e Arbitragem",
  },
  {
    id: "ENTIDADE SINDICAL",
    label: "Entidade Sindical",
  },
  {
    id: "ESTABELECIMENTO, NO BRASIL, DE EMPRESA BINACIONAL ARGENTINO-BRASILEIRA",
    label:
      "Estabelecimento, no Brasil, de Empresa Binacional Argentino-Brasileira",
  },
  {
    id: "ESTABELECIMENTO, NO BRASIL, DE FUNDAÇÃO OU ASSOCIAÇÃO ESTRANGEIRAS",
    label: "Estabelecimento, no Brasil, de Fundação ou Associação Estrangeiras",
  },
  {
    id: "ESTABELECIMENTO, NO BRASIL, DE SOCIEDADE ESTRANGEIRA",
    label: "Estabelecimento, no Brasil, de Sociedade Estrangeira",
  },
  {
    id: "ESTADO OU DISTRITO FEDERAL",
    label: "Estado ou Distrito Federal",
  },
  {
    id: "FRENTE PLEBISCITÁRIA OU REFERENDÁRIA",
    label: "Frente Plebiscitária ou Referendária",
  },
  {
    id: "FUNDAÇÃO OU ASSOCIAÇÃO DOMICILIADA NO EXTERIOR",
    label: "Fundação ou Associação Domiciliada no Exterior",
  },
  {
    id: "FUNDAÇÃO PRIVADA",
    label: "Fundação Privada",
  },
  {
    id: "FUNDAÇÃO PÚBLICA DE DIREITO PRIVADO ESTADUAL OU DO DISTRITO FEDERAL",
    label:
      "Fundação Pública de Direito Privado Estadual ou do Distrito Federal",
  },
  {
    id: "FUNDAÇÃO PÚBLICA DE DIREITO PRIVADO FEDERAL",
    label: "Fundação Pública de Direito Privado Federal",
  },
  {
    id: "FUNDAÇÃO PÚBLICA DE DIREITO PRIVADO MUNICIPAL",
    label: "Fundação Pública de Direito Privado Municipal",
  },
  {
    id: "FUNDAÇÃO PÚBLICA DE DIREITO PÚBLICO ESTADUAL OU DO DISTRITO FEDERAL",
    label:
      "Fundação Pública de Direito Público Estadual ou do Distrito Federal",
  },
  {
    id: "FUNDAÇÃO PÚBLICA DE DIREITO PÚBLICO FEDERAL",
    label: "Fundação Pública de Direito Público Federal",
  },
  {
    id: "FUNDAÇÃO PÚBLICA DE DIREITO PÚBLICO MUNICIPAL",
    label: "Fundação Pública de Direito Público Municipal",
  },
  {
    id: "FUNDO PRIVADO",
    label: "Fundo Privado",
  },
  {
    id: "FUNDO PÚBLICO DA ADMINISTRAÇÃO DIRETA ESTADUAL OU DO DISTRITO FEDERAL",
    label:
      "Fundo Público da Administração Direta Estadual ou do Distrito Federal",
  },
  {
    id: "FUNDO PÚBLICO DA ADMINISTRAÇÃO DIRETA FEDERAL",
    label: "Fundo Público da Administração Direta Federal",
  },
  {
    id: "FUNDO PÚBLICO DA ADMINISTRAÇÃO DIRETA MUNICIPAL",
    label: "Fundo Público da Administração Direta Municipal",
  },
  {
    id: "FUNDO PÚBLICO DA ADMINISTRAÇÃO INDIRETA ESTADUAL OU DO DISTRITO FEDERAL",
    label:
      "Fundo Público da Administração Indireta Estadual ou do Distrito Federal",
  },
  {
    id: "FUNDO PÚBLICO DA ADMINISTRAÇÃO INDIRETA FEDERAL",
    label: "Fundo Público da Administração Indireta Federal",
  },
  {
    id: "FUNDO PÚBLICO DA ADMINISTRAÇÃO INDIRETA MUNICIPAL",
    label: "Fundo Público da Administração Indireta Municipal",
  },
  {
    id: "GRUPO DE SOCIEDADES",
    label: "Grupo de Sociedades",
  },
  {
    id: "LEILOEIRO",
    label: "Leiloeiro",
  },
  {
    id: "MUNICÍPIO",
    label: "Município",
  },
  {
    id: "ORGANIZAÇÃO INTERNACIONAL",
    label: "Organização Internacional",
  },
  {
    id: "ORGANIZAÇÃO RELIGIOSA",
    label: "Organização Religiosa",
  },
  {
    id: "ÓRGÃO DE DIREÇÃO LOCAL DE PARTIDO POLÍTICO",
    label: "Órgão de Direção Local de Partido Político",
  },
  {
    id: "ÓRGÃO DE DIREÇÃO NACIONAL DE PARTIDO POLÍTICO",
    label: "Órgão de Direção Nacional de Partido Político",
  },
  {
    id: "ÓRGÃO DE DIREÇÃO REGIONAL DE PARTIDO POLÍTICO",
    label: "Órgão de Direção Regional de Partido Político",
  },
  {
    id: "ÓRGÃO PÚBLICO AUTÔNOMO ESTADUAL OU DO DISTRITO FEDERAL",
    label: "Órgão Público Autônomo Estadual ou do Distrito Federal",
  },
  {
    id: "ÓRGÃO PÚBLICO AUTÔNOMO FEDERAL",
    label: "Órgão Público Autônomo Federal",
  },
  {
    id: "ÓRGÃO PÚBLICO AUTÔNOMO MUNICIPAL",
    label: "Órgão Público Autônomo Municipal",
  },
  {
    id: "ÓRGÃO PÚBLICO DO PODER EXECUTIVO ESTADUAL OU DO DISTRITO FEDERAL",
    label: "Órgão Público do Poder Executivo Estadual ou do Distrito Federal",
  },
  {
    id: "ÓRGÃO PÚBLICO DO PODER EXECUTIVO FEDERAL",
    label: "Órgão Público do Poder Executivo Federal",
  },
  {
    id: "ÓRGÃO PÚBLICO DO PODER EXECUTIVO MUNICIPAL",
    label: "Órgão Público do Poder Executivo Municipal",
  },
  {
    id: "ÓRGÃO PÚBLICO DO PODER JUDICIÁRIO ESTADUAL",
    label: "Órgão Público do Poder Judiciário Estadual",
  },
  {
    id: "ÓRGÃO PÚBLICO DO PODER JUDICIÁRIO FEDERAL",
    label: "Órgão Público do Poder Judiciário Federal",
  },
  {
    id: "ÓRGÃO PÚBLICO DO PODER LEGISLATIVO ESTADUAL OU DO DISTRITO FEDERAL",
    label: "Órgão Público do Poder Legislativo Estadual ou do Distrito Federal",
  },
  {
    id: "ÓRGÃO PÚBLICO DO PODER LEGISLATIVO FEDERAL",
    label: "Órgão Público do Poder Legislativo Federal",
  },
  {
    id: "ÓRGÃO PÚBLICO DO PODER LEGISLATIVO MUNICIPAL",
    label: "Órgão Público do Poder Legislativo Municipal",
  },
  {
    id: "OUTRAS INSTITUIÇÕES EXTRATERRITORIAIS",
    label: "Outras Instituições Extraterritoriais",
  },
  {
    id: "PRODUTOR RURAL (PESSOA FÍSICA)",
    label: "Produtor Rural (Pessoa Física)",
  },
  {
    id: "REPRESENTAÇÃO DIPLOMÁTICA ESTRANGEIRA",
    label: "Representação Diplomática Estrangeira",
  },
  {
    id: "SERVIÇO NOTARIAL E REGISTRAL (CARTÓRIO)",
    label: "Serviço Notarial e Registral (Cartório)",
  },
  {
    id: "SERVIÇO SOCIAL AUTÔNOMO",
    label: "Serviço Social Autônomo",
  },
  {
    id: "SOCIEDADE ANÔNIMA ABERTA",
    label: "Sociedade Anônima Aberta",
  },
  {
    id: "SOCIEDADE ANÔNIMA FECHADA",
    label: "Sociedade Anônima Fechada",
  },
  {
    id: "SOCIEDADE DE ECONOMIA MISTA",
    label: "Sociedade de Economia Mista",
  },
  {
    id: "SOCIEDADE EM CONTA DE PARTICIPAÇÃO",
    label: "Sociedade em Conta de Participação",
  },
  {
    id: "SOCIEDADE EMPRESÁRIA EM COMANDITA POR AÇÕES",
    label: "Sociedade Empresária em Comandita por Ações",
  },
  {
    id: "SOCIEDADE EMPRESÁRIA EM COMANDITA SIMPLES",
    label: "Sociedade Empresária em Comandita Simples",
  },
  {
    id: "SOCIEDADE EMPRESÁRIA EM NOME COLETIVO",
    label: "Sociedade Empresária em Nome Coletivo",
  },
  {
    id: "SOCIEDADE EMPRESÁRIA LIMITADA",
    label: "Sociedade Empresária Limitada",
  },
  {
    id: "SOCIEDADE SIMPLES EM COMANDITA SIMPLES",
    label: "Sociedade Simples em Comandita Simples",
  },
  {
    id: "SOCIEDADE SIMPLES EM NOME COLETIVO",
    label: "Sociedade Simples em Nome Coletivo",
  },
  {
    id: "SOCIEDADE SIMPLES LIMITADA",
    label: "Sociedade Simples Limitada",
  },
  {
    id: "SOCIEDADE SIMPLES PURA",
    label: "Sociedade Simples Pura",
  },
  {
    id: "SOCIEDADE UNIPESSOAL DE ADVOGADOS",
    label: "Sociedade Unipessoal de Advogados",
  },
  {
    id: "UNIÃO",
    label: "União",
  },
];

const legalEntityWithConventionDocument = [
  "CONSÓRCIO PÚBLICO DE DIREITO PÚBLICO (ASSOCIAÇÃO PÚBLICA)",
  "CONDOMÍNIO EDILÍCIO",
  "ESTABELECIMENTO, NO BRASIL, DE FUNDAÇÃO OU ASSOCIAÇÃO ESTRANGEIRAS",
  "FUNDAÇÃO OU ASSOCIAÇÃO DOMICILIADA NO EXTERIOR",
  "DEMAIS CONDOMÍNIOS",
  "ASSOCIAÇÃO PRIVADA",
];

const powerCompanyWithConventionDocument: string[] = [
  "EDP",
  "CPFL",
  "ELEKTRO",
  "SANTA CRUZ",
  "EQUATORIAL",
  "CPFL SANTA CRUZ",
  "CPFL PIRATININGA",
];

export {
  naturezaJuridica,
  legalEntityWithConventionDocument,
  powerCompanyWithConventionDocument,
};
