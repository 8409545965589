import { useApiQuery } from 'hooks/api'

interface DataParams {
  customerId: string
  consultantId: string
}

export function useGetCustomer(
  { customerId, consultantId }: DataParams,
  options?: any
) {
  return useApiQuery({
    instance: 'igreen',
    endpoint: `/cliente/${consultantId}/${customerId}`,
    queryKey: ['cliente', consultantId, customerId],
    message: {
      error: 'Não foi possível acessar os dados do cliente.',
    },
    options,
  })
}
