import { useApiQuery } from 'hooks/api'

export function useGetConsultant(consultantId: string, options?: any) {
  return useApiQuery({
    instance: "igreen",
    endpoint: `/consultor/${consultantId}`,
    queryKey: [consultantId],
    message: {
      error: "Não foi possível recuperar os dados do licenciado.",
    },
    options,
  });
}
