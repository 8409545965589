import styled, { css } from 'styled-components'
import { TextField } from '@mui/material'

export const MuiTextField = styled(TextField)`
  background-color: transparent;
  font-size: 16px;
  border-radius: 12px;

  .MuiFormLabel-root.MuiInputLabel-root {
    font-family: Montserrat;
  }

  .MuiFormLabel-asterisk {
    color: #ff0000;
  }

  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 55px;
  }

  .MuiFilledInput-root {
    font-family: Montserrat;
    border: 1px solid #808080;
    border-radius: 12px;
    background-color: transparent;

    &:focus {
      border: 1px solid #00b22a;
    }

    &:hover {
      border: 1px solid #00b22a;
    }

    ${({ error }) =>
      error &&
      css`
        font-family: Montserrat;
        border: 1px solid #ff0000 !important;
      `}
  }

  .MuiFilledInput-root.Mui-focused {
    background-color: transparent;
    border: 1px solid #00b22a;
  }

  input:-webkit-autofill {
    border-radius: 12px;
  }

  input:autofill {
    background-color: transparent;
  }

  label.Mui-focused {
    color: #0009;
  }
`
