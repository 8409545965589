import { ReactNode } from 'react'

import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from './query-client'

interface PropsQueryProvider {
  children: ReactNode,
}

export function QueryProvider({ children }: PropsQueryProvider) {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  )
}