import { useMutation } from "@tanstack/react-query";
import { axiosInstances, TypeDefaultApiParams } from "./shared";
import toast from "react-hot-toast";

interface ParamsUseApiMutate extends TypeDefaultApiParams {
  method: "put" | "post" | "delete" | "patch";
  onUploadProgress?: (value: number) => void;
}

export const useApiMutate = <Param, Response>({
  instance,
  method,
  endpoint,
  paramsSchema,
  responseSchema,
  message,
  onUploadProgress,
}: ParamsUseApiMutate) => {
  const apiRequest = async (body: Param) => {
    try {
      const response = await axiosInstances[instance][method]<Response>(
        endpoint,
        body,
        {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / (progressEvent?.total as number)
            );
            onUploadProgress?.(progress);
          },
        }
      );

      const data = response?.data;

      if (!responseSchema) return data;

      const safeData = responseSchema.safeParse(data);

      if (safeData.success) return safeData.data;

      toast.error(message?.error || "Houve um erro nos dados recebidos.");
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const handleMutate = (body: Param) => {
    if (!paramsSchema) return apiRequest(body);

    const safeParams = paramsSchema.safeParse(body);

    if (!safeParams.success) {
      toast.error("Houve um problema com os dados para realizar a solicitação");

      return new Promise((resolve) => {
        resolve({});
      });
    }

    return apiRequest(body);
  };

  return useMutation(handleMutate);
};
