import styled from "styled-components";

const Container = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

const FormLayout = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Text = styled.div`
  margin: 0;
  font-size: 14px;
  color: #181d11;
  font-family: Montserrat;
  font-weight: 600;
`;

export { Container, Text, FormLayout };