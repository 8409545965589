import * as z from "zod";

import { lessUnderscore } from "utils";

export const schemaAddressData = z.object({
  cep: z.preprocess(lessUnderscore, z.string().length(10, "CEP Obrigatório")),
  complemento: z.string().optional().default("Complemento obrigatório"),
  endereco: z.string().min(3, "Endereço obrigatório"),
  numero: z.string().min(1, "Número obrigatório"),
  bairro: z.string().min(1, "Bairro obrigatório"),
  cidade: z.string().min(3, "Cidade obrigatória"),
  uf: z.string().length(2, "UF obrigatório"),
  cepChecked: z.boolean().default(false),
  complementoChecked: z.boolean().default(false),
  enderecoChecked: z.boolean().default(false),
  numeroChecked: z.boolean().default(false),
  bairroChecked: z.boolean().default(false),
  cidadeChecked: z.boolean().default(false),
  ufChecked: z.boolean().default(false),
}).superRefine((values, ctx) => {
  if (!!values.cep.length && !values.cepChecked) {
    ctx.addIssue({
      message: "Confirme o número de CEP",
      code: z.ZodIssueCode.custom,
      path: ["cep"],
    });
  }

  if (!!values.endereco.length && !values.enderecoChecked) {
    ctx.addIssue({
      message: "Confirme o endereço",
      code: z.ZodIssueCode.custom,
      path: ["endereco"],
    });
  }

  if (!!values.complemento.length && !values.complementoChecked) {
    ctx.addIssue({
      message: "Confirme o complemento",
      code: z.ZodIssueCode.custom,
      path: ["complemento"],
    });
  }

  if (!!values.numero.length && !values.numeroChecked) {
    ctx.addIssue({
      message: "Confirme o número",
      code: z.ZodIssueCode.custom,
      path: ["numero"],
    });
  }

  if (!!values.bairro.length && !values.bairroChecked) {
    ctx.addIssue({
      message: "Confirme o bairro",
      code: z.ZodIssueCode.custom,
      path: ["bairro"],
    });
  }

  if (!!values.uf.length && !values.ufChecked) {
    ctx.addIssue({
      message: "Confirme a UF",
      code: z.ZodIssueCode.custom,
      path: ["uf"],
    });
  }

  if (!!values.cidade.length && !values.cidadeChecked) {
    ctx.addIssue({
      message: "Confirme a cidade",
      code: z.ZodIssueCode.custom,
      path: ["cidade"],
    });
  }
});
