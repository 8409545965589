import { forwardRef } from 'react'
import { Virtuoso } from 'react-virtuoso'
import _find from 'lodash/find'
import { useController, useFormContext } from 'react-hook-form'
import { Autocomplete as MuiAutoComplete } from '@mui/material'
import { MuiTextField } from 'components/Form/TextField/styles'
import { AutoCompleteProps } from './AutoComplete.types'

const ListBox = forwardRef<any, any>(({ children, ...rest }, ref) => {
  const itemCount = Array.isArray(children) ? children.length : 0

  return (
    <div ref={ref}>
      <Virtuoso
        {...rest}
        style={{ height: '20vh' }}
        data={children}
        totalCount={itemCount}
        itemContent={(_, data) => data}
      />
    </div>
  )
})

const AutoComplete = ({
  options,
  label,
  name,
  required,
}: AutoCompleteProps) => {
  const { control } = useFormContext()
  const {
    field,
    formState: { errors },
  } = useController({ name, control, defaultValue: null })

  const errorMessage = errors[name]?.message
  return (
    <MuiAutoComplete
      options={options}
      value={_find(options, { id: field.value })}
      fullWidth
      onChange={(_, value) => {
        field.onChange(value?.id)
      }}
      noOptionsText="Nenhuma opção encontrada"
      isOptionEqualToValue={(option, value) =>
        value ? option.id === value.id : false
      }
      renderInput={(params: any) => {
        return (
          <MuiTextField
            {...params}
            variant="filled"
            label={label}
            error={!!errors[name]}
            helperText={errorMessage as string}
            required={required}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
          />
        )
      }}
      ListboxComponent={ListBox}
    />
  )
}

export { AutoComplete }
