import * as z from 'zod'

import {useApiMutate} from "hooks/api";

const paramsSchema = z.object({
  idconsultor: z.number(),
})

const responseSchema = z.object({
  auth: z.object({
    access: z.string(),
  })
})

type TypeParams = z.infer<typeof paramsSchema>
type TypeResponse = z.infer<typeof responseSchema>

export function usePostLogin() {
  return useApiMutate<TypeParams, TypeResponse>({
    instance: "igreen",
    method: 'post',
    endpoint: '/auth/consultor',
    responseSchema,
    paramsSchema,
  })
}
