import {useQuery} from '@tanstack/react-query'
import * as z from 'zod'

import {axiosViaCep} from "config/api";
import {TypeReactUseQueryOptions} from "config/query";

const paramsSchema = z.object({
  cep: z.string().transform(cep => cep.replace(/[^0-9]/g, '')).pipe(z.string().min(8, 'Cep inválido')),
})

const responseGetCepInfoSchema = z.object({
  bairro: z.string().default(""),
  localidade: z.string().default(""),
  logradouro: z.string().default(""),
  uf: z.string().default(""),
}).transform(({localidade, ...rest}) => ({
  ...rest,
  localidade,
  cidade: localidade,
}))


type ParamsGetCepInfo = z.infer<typeof paramsSchema>
type ResponseGetCepInfo = z.infer<typeof responseGetCepInfoSchema>

function getCepInfo({ cep }: ParamsGetCepInfo) {
  const defaultSchema = responseGetCepInfoSchema.safeParse({});
  const unformattedCep = cep.replace(/[^0-9]/g, "");
  return axiosViaCep
    .get<ResponseGetCepInfo>(`/${unformattedCep}/json/`)
    .then((response) => responseGetCepInfoSchema.parse(response.data))
    .catch(() => {
      if (defaultSchema.success) return defaultSchema.data;

      return {} as ResponseGetCepInfo;
    });
}

export function useGetCepInfo(
  params: ParamsGetCepInfo,
  options = {} as TypeReactUseQueryOptions
) {

  const {success: paramsSuccess } = paramsSchema.safeParse(params);

  const defaultSchema = responseGetCepInfoSchema.safeParse({})

  return useQuery<ResponseGetCepInfo>(['get-cep', params.cep], () => getCepInfo(params), {
    ...options,
    initialData: defaultSchema.success ? defaultSchema.data : {} as ResponseGetCepInfo,
    enabled: paramsSuccess && (options?.enabled === undefined || options?.enabled),
  });
}
