export const MiniOrangeTitleIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={56}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#F46D00"
      strokeLinecap="round"
      strokeWidth={8}
      d="M4.67 11.866C11.977 7.718 31.866-.346 51.44 9.169"
    />
  </svg>
)
