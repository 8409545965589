import { ChangeEvent, useState } from 'react'

import toast from 'react-hot-toast'
import { useSearchParams } from 'react-router-dom'
import { useMediaQuery, Button } from '@mui/material'

import { useGlobalData } from 'hooks/api'
import { Subtitle, BoxComponent, CustomTextField } from 'components'
import logo from 'assets/logo.png'
import solarPanel from 'assets/png/solar-panel.png'
import activeLogo from 'assets/png/igreen-active.png'
import orangeEllipse from 'assets/png/orange-ellipse.png'
import greenShadow from 'assets/png/green-shadow.png'
import solarPanelMobile from 'assets/png/solar-panel-mobile.png'
import { HomeIcon } from 'assets/svg/home-icon'
import { BuildingIcon } from 'assets/svg'
import { useAuth } from 'features/connection-form'

import {
  Content,
  MainContainer,
  MainContent,
  MainTitle,
  IgreenLogo,
  SolarPanelImg,
  OrangeEllipseImg,
  ActionContainer,
  ActiveIgreenImg,
  IgreenShadowImg,
  SolarPanelMobile,
  BoxContainer,
  CurveLine,
  MiniCurveLine,
  SelectAccountTypeContainer,
  ConsumptionInputContainer,
} from './styles'

import { ShowSavingsSlider } from 'features/connection-form/components/index.ts'
import { onlyNumbers } from 'utils'

const ActionButtons = ({
  onAction,
}: {
  onAction: (v: 'PF' | 'PJ') => void
}) => {
  const isTablet = useMediaQuery('(max-width:1023px)')

  if (isTablet) {
    return (
      <SelectAccountTypeContainer>
        <Button
          sx={{
            '&:hover': {
              color: '#ffffff',
              background: '#00b22a',
            },
          }}
          startIcon={<HomeIcon />}
          onClick={() => onAction('PF')}
        >
          Residência
        </Button>
        <Button
          startIcon={<BuildingIcon />}
          sx={{
            '&:hover': {
              color: '#ffffff',
              background: '#00b22a',
            },
          }}
          onClick={() => onAction('PJ')}
        >
          Empresa
        </Button>
      </SelectAccountTypeContainer>
    )
  }

  return (
    <BoxContainer>
      <BoxComponent onClick={() => onAction('PF')} icon={<HomeIcon />}>
        Residência
      </BoxComponent>
      <BoxComponent onClick={() => onAction('PJ')} icon={<BuildingIcon />}>
        Empresa
      </BoxComponent>
    </BoxContainer>
  )
}

export function StartFormPage({ onStart }: { onStart: () => void }) {
  const [consumption, setConsumption] = useState<number>(0)
  const [formattedConsuption, setFormattedConsuption] = useState<string>("")
  const [searchParams] = useSearchParams()
  const sponsorId = Number(searchParams.get('id') || '')
  const { setAccountType } = useGlobalData()
  const isTablet = useMediaQuery('(max-width: 1023px)')

  const { token } = useAuth({ sponsorId })

  const handleStartForm = (type: 'PF' | 'PJ') => {
    if (!token || !sponsorId) {
      toast.error(`Infelizmente voce não tem autorização para se cadastrar.`)

      return
    }

    setAccountType(type)
    onStart()
  }
  
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    
    const inputValue =  parseFloat( onlyNumbers( e.target.value || "0") ) 
    const formatedValue = (inputValue / 100 ).toFixed(2).replace(".",","); 
 
    setFormattedConsuption(formatedValue);

    return setConsumption( parseFloat(formatedValue) );
  }

  return (
    <MainContainer>
      <IgreenShadowImg src={greenShadow} alt="igreen" />  
      <ActiveIgreenImg src={activeLogo} alt="igreen-active" />
      <OrangeEllipseImg src={orangeEllipse} alt="orange-ellipse" />

      <Content>
        <IgreenLogo src={logo} alt="igreen" />

        <MainTitle>
          Diga olá para sua&nbsp;
          <span>nova {isTablet ? <MiniCurveLine /> : <CurveLine />}</span> conta
          de energia.
        </MainTitle>

        <MainContent>
          <Subtitle>
            Você está a um passo de ter uma conta de luz mais amigável ao seu
            bolso. Para começar nos diga quanto você paga em média na sua conta
            de luz.
          </Subtitle>

          <ConsumptionInputContainer>
            <CustomTextField
              required
              label="Valor mensal da conta de energia"
              value={formattedConsuption}
              onChange={handleChange}
              InputProps={{
                disableUnderline: true,
                startAdornment: <div style={{ marginTop: 22 }}>R$</div>,
              }}
              variant="filled"
              fullWidth
            />
          </ConsumptionInputContainer>

          <ShowSavingsSlider onChange={setConsumption} value={consumption} />

          {consumption > 0 && (
            <ActionContainer>
              <h3>Esta economia será para sua residência ou empresa?</h3>
              <ActionButtons onAction={handleStartForm} />
            </ActionContainer>
          )}
        </MainContent>
      </Content> 
      {isTablet ? (
        <SolarPanelMobile src={solarPanelMobile} alt="solar-panel" />
      ) : (
        <SolarPanelImg src={solarPanel} alt="solar-panel" />
      )}
    </MainContainer>
  )
}
