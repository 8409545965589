const API_IGREEN = import.meta.env.VITE_API_IGREEN
const API_VIA_CEP = import.meta.env.VITE_API_VIA_CEP
const API_COMERC = import.meta.env.VITE_API_COMERC
const API_TOKEN_COMERC = import.meta.env.VITE_API_TOKEN_COMERC
const ENV = import.meta.env.VITE_ENV

export {
  API_IGREEN,
  API_VIA_CEP,
  API_COMERC,
  API_TOKEN_COMERC,
  ENV
}
