import jwt_decode, {JwtPayload} from "jwt-decode";

export function isValidToken(token?: string) {
  try {
    if (!token) return false

    const decodedToken = jwt_decode<JwtPayload>(token)

    const now = Math.floor(Date.now() / 1000);

    const isTokenExpired =
      typeof decodedToken.exp !== 'undefined' && decodedToken.exp < now;

    return !isTokenExpired;
  } catch {
    return false
  }
}
