import axios from 'axios'

import { API_IGREEN } from 'config/env'

export const axiosIgreen = axios.create({
  baseURL: API_IGREEN,
})

axiosIgreen.interceptors.request.use((config: any) => {
  const localStorageToken = localStorage.getItem('igreen-token')

  if (!localStorageToken) return config

  const token = JSON.parse(localStorageToken)

  const headers = {
    ...config.headers,
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  }

  return {
    ...config,
    headers,
  }
})

