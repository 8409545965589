import { isoDateToString } from 'utils'

type DataRecord = Record<string, any>

export const personalInfoFormDefaultValues = ({
  initialData,
  currentData,
}: {
  initialData: DataRecord
  currentData: DataRecord
}) => {
  const getDefaultValue = (key: string) => {
    if (key === 'dtnasc') {
      return currentData[key] || isoDateToString(initialData?.dtnasc) || ''
    }

    return currentData[key] || initialData[key] || ''
  }

  return {
    cpf_cnpj: getDefaultValue("cpf_cnpj"),
    nome: getDefaultValue("nome"),
    dtnasc: getDefaultValue("dtnasc"),
    email: getDefaultValue("email"),
    rg: getDefaultValue("rg"),
    emissor: getDefaultValue("emissor"),
    celular: getDefaultValue("celular"),
    profissao: getDefaultValue("profissao"),
    estadocivil: getDefaultValue("estadocivil"),
    logindistribuidora: getDefaultValue("logindistribuidora"),
    senhadistribuidora: getDefaultValue("senhadistribuidoradistribuidora"),
  };
}
