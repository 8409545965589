import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 45px;
`

export const Text = styled.p`
  margin: 0;
  color: #181d11;
  font-family: 'Nexa Bold';
  font-size: 16px;
  font-weight: 700;
`

export const FormLayout = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`

export const ImageHint = styled.span`
  display: flex;
  color: #626972;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;

  span {
    color: #f46d00;
  }
`