export const PdfIcon = (props: any) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 21.995H6.5C5.96952 21.995 5.46073 21.7844 5.08563 21.4093C4.71052 21.0342 4.5 20.5254 4.5 19.995V5.99496C4.5 5.46449 4.71052 4.95569 5.08563 4.58059C5.46073 4.20549 5.96952 3.99496 6.5 3.99496H17.5C18.0305 3.99496 18.5393 4.20549 18.9144 4.58059C19.2895 4.95569 19.5 5.46449 19.5 5.99496V19.995C19.5 20.5254 19.2895 21.0342 18.9144 21.4093C18.5393 21.7844 18.0305 21.995 17.5 21.995Z"
      stroke="#181D11"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0412 6.87018C12.0081 6.87006 11.9763 6.88323 11.953 6.90674C11.9297 6.93025 11.9168 6.96213 11.9172 6.99523C11.9172 7.04591 11.9477 7.09157 11.9946 7.11086C12.0414 7.13016 12.0953 7.11927 12.131 7.0833C12.1667 7.04733 12.1771 6.99338 12.1574 6.94668C12.1378 6.89999 12.0919 6.86978 12.0412 6.87018"
      stroke="#181D11"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 10.9951H16"
      stroke="#181D11"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13 16.9952H8"
      stroke="#181D11"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 13.9952H16"
      stroke="#181D11"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
