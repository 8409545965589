import { useMediaQuery } from "usehooks-ts";

const medias = {
    "mobile": '(max-width:767px)',
    "tablet": '(max-width:767px)',
    "computer": '(min-width:1024px)',
    "notebook": '(min-width:1024px) and (max-width:1399px)',
    "desktop": '(min-width:1400px)',
}

type Medias = 'mobile' | 'tablet' | 'computer' | 'notebook' | 'desktop'

export function useMedia(media: Medias) {
  return useMediaQuery(medias[media]);
}
