import { createBrowserRouter } from 'react-router-dom'

import { ConnectionFormPage } from "pages/connection-form";

export const connectionFormRoutes = createBrowserRouter([
  {
    path: "/",
    element: <ConnectionFormPage />,
  },
]);
