import { useApiMutate } from 'hooks/api'

export function usePatchClientInfo(id: string, options?: any) {
  return useApiMutate({
    instance: 'igreen',
    method: 'patch',
    endpoint: `/cliente/${id}`,
    message: {
      error: 'Não foi possível atualizar o cadastro.',
    },
    options,
  })
}
