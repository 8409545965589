import styled, {css} from 'styled-components'

export const Container = styled.div`
  width: 100%;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 24px 0;
`

export const Text = styled.p`
  margin: 0;
  color: #181d11;
  font-family: 'Nexa Bold' ;
  font-size: 16px;
  font-weight: 700;
`

export const RoundedBackground = styled.div<{$backgroundColor: string}>`
  ${({ $backgroundColor }) => css`background: ${$backgroundColor};`}
  min-width: 48px;
  min-height: 48px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
`
