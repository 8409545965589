import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
`

export const Text = styled.p`
  margin: 0;
  color: #181d11;
  font-family: 'Nexa Bold';
  font-size: 16px;
  font-weight: 700;
`

export const FilePassword = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
`

export const FileTypeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 9px;
`

export const ImageContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-wrap: wrap;

  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
`

export const ImageHint = styled.span`
  display: flex;
  color: #626972;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;

  span {
    color: #f46d00;
  }
`

export const FieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const FormLayout = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
`
