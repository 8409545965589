import * as z from "zod";

const schemaPaymentVoucher = (customer: any) =>
  z.object({
    caminhocomprovante: customer
      ? z.string().optional()
      : z.string().min(1, "Comprovante de pagamento é obrigatório"),
  });

export { schemaPaymentVoucher };
