type DataRecord = Record<string, any>;

export const energyProviderFormDefaultValues = ({
  initialData,
  currentData,
}: {
  initialData: DataRecord;
  currentData: DataRecord;
}) => {
  const handleCurrency = (value: string) => {
    const valueFloat = parseFloat(value);
    return valueFloat.toFixed(2).replace('.', ',')
  }

  const getDefaultValue = (key: string) => {
    const partData = initialData?.classificacao.split(' - ')
    const classification = partData[0]
    const energyBox = partData[1]

    if (key === 'classificacao') {
      return currentData[key] || classification || ''
    }

    if (key === 'energyBox') {
      return currentData[key] || energyBox || ''
    }

    if (key === 'valorkwh' || key === 'valorcontribuicao') {
      return currentData[key] || handleCurrency(initialData[key]) || ''
    }

    return currentData[key] || initialData[key] || ''
  }

  
  return {
    concessionaria: getDefaultValue('concessionaria'),
    classificacao: getDefaultValue('classificacao'),
    energyBox: getDefaultValue('energyBox'),
    valorkwh: getDefaultValue('valorkwh'),
    numcliente: getDefaultValue('numcliente'),
    valorcontribuicao: getDefaultValue('valorcontribuicao'),
    numinstalacao: getDefaultValue('numinstalacao'),
  }
}
