import { ReactElement, useRef } from "react";
import { useController, useFormContext } from "react-hook-form";
import { SuccessIconSmall } from "assets/svg";

import {
  Container,
  ClickableContainer,
  Text,
  HiddenInput,
  Button,
  FileName,
  FileContainer,
  ErrorMessage,
} from "./styles";

const MAXIMUM_FILE_SIZE = 50 * 1024 * 1024;

const EditButton: React.FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button type="button" onClick={onClick}>
    Alterar
  </Button>
);

interface FileUploaderInputProps {
  name: string;
  icon?: ReactElement;
  onChange?: (file: string) => void;
  description?: string;
  accept: string;
  disabled?: boolean;
}

export const FileUploaderInput: React.FC<FileUploaderInputProps> = ({
  name,
  icon,
  description,
  onChange,
  accept,
  disabled,
}) => {
  const { control, setError, resetField } = useFormContext();
  const {
    field,
    formState: { errors },
  } = useController({ name, control, defaultValue: "" });

  const fileInputRef = useRef<HTMLInputElement | null>();
  const file = field.value;
  const hasError = errors[name] && !file?.length;
  const fileName = file?.split("-")[0];

  const convertFileToBase64 = (selectedFile: File) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const fileBase64 = reader.result as string;
      if (onChange) {
        onChange(fileBase64);
      }
      field.onChange(`${selectedFile.name}-${fileBase64}`);
    };

    reader.readAsDataURL(selectedFile);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (!selectedFile) return;

    if (selectedFile.size > MAXIMUM_FILE_SIZE) {
      setError(name, {
        type: "manual",
        message: "Valor máximo de 50MB para arquivos",
      });
      return resetField(name,{ keepError : true });
    }

    convertFileToBase64(selectedFile);
  };

  const handleRef = (element: HTMLInputElement) => {
    fileInputRef.current = element;
  };

  const handleSuccessMessage = () => {
    return file.includes(".pdf") ? "PDF anexado" : "Imagem anexada";
  };

  return (
    <Container>
      <ClickableContainer
        htmlFor={name}
        $success={file?.length > 0}
        $error={hasError}
        $disabled={disabled}
      >
        {file ? <SuccessIconSmall /> : icon}
        <Text>{file ? handleSuccessMessage() : description}</Text>
        <HiddenInput
          ref={handleRef}
          name={name}
          type="file"
          id={name}
          accept={accept}
          onChange={handleFileChange}
          disabled={disabled}
          value={""}
        />
      </ClickableContainer>
      {hasError && (
        <ErrorMessage>{errors[name]?.message as string}</ErrorMessage>
      )}
      {file && (
        <FileContainer>
          <FileName>{fileName.includes("https") ? "" : fileName}</FileName>
          <EditButton onClick={() => fileInputRef.current?.click()} />
        </FileContainer>
      )}
    </Container>
  );
};
