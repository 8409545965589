import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  useMediaQuery,
} from "@mui/material";
import { useController, useFormContext } from "react-hook-form";
import { Container } from "./Checkbox.styles";

interface Props extends CheckboxProps {
  name: string;
  onChange?: (params: any) => void;
}

const Checkbox: React.FC<Props> = ({ name, onChange, ...props }) => {
  const { control } = useFormContext();
  const {
    field,
    fieldState: { invalid },
  } = useController({ name, control });
  const isMobile = useMediaQuery("(max-width: 1023px)");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(event.target.checked);

    if (onChange) {
      onChange({ name, value: field.value });
    }
  };

  return (
    <Container>
      <MuiCheckbox
        {...props}
        size={isMobile ? "small" : "medium"}
        sx={{
          color: invalid ? "#d32f2f" : null,
          borderWidth: "1px",
          "&.Mui-checked": {
            color: "#00b22a",
          },
        }}
        name={name}
        checked={!!field.value}
        onChange={handleChange}
      />
    </Container>
  );
};

export { Checkbox };
