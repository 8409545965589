import { AxiosRequestConfig } from 'axios'
import * as z from 'zod'
import { axiosIgreen, axiosViaCep, axiosComerc } from "../../config/api";

export interface TypeDefaultApiParams {
  instance: 'igreen' | 'via-cep' | 'comerc';
  endpoint: string;
  body?: AxiosRequestConfig;
  paramsSchema?: z.ZodTypeAny,
  responseSchema?: z.ZodTypeAny,
  message?: {
    error?: string;
    success?: string;
  },
  queryKey?: string[]
  options?: any;
}

export const axiosInstances = {
  igreen: axiosIgreen,
  'via-cep': axiosViaCep,
  comerc: axiosComerc
}
