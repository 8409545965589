import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;
  position: relative;
`;

export const Text = styled.p`
  margin: 0;
  color: #181d11;
  font-family: "Nexa Bold";
  font-size: 16px;
  font-weight: 700;
`;

export const FieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
