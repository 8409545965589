import { Slider, SliderProps } from "@mui/material";
import styled from "styled-components";

export function SliderComponent(props: SliderProps) {
  return <CustomSliderWrapper aria-labelledby="non-linear-slider" {...props} />;
}

const CustomSliderWrapper = styled(Slider)`
  .MuiSlider-thumb {
    color: #00b22a;
    border: 4px solid #ffffff;
    height: 21px;
    width: 21px;
    filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
  }

  .MuiSlider-thumb:focus {
    outline: none;
  }

  .MuiSlider-thumb.Mui-focusVisible {
    box-shadow: none;
  }

  & .MuiSlider-thumb:hover {
    box-shadow: none;
  }

  .MuiSlider-rail {
    background-color: #e9eef2;
    height: 5px;
  }

  .MuiSlider-rail {
    background-color: #e9eef2;
    height: 5px;
  }

  .MuiSlider-track {
    background-color: #00b22a;
    color: #00b22a;
    height: 5px;
    border: none;
  }
`;
