type DataRecord = Record<string, any>

export const uploadEnergyBillFormDefaultValues = ({
  initialData,
  currentData,
}: {
  initialData: DataRecord
  currentData: DataRecord
}) => {
  const getDefaultValue = (key: string) => {
    return currentData[key] || initialData[key] || ''
  }

  return {
    senhapdf: getDefaultValue('senhapdf'),
    caminhoarquivo: getDefaultValue('caminhoarquivo'),
    caminhoarquivoenergia2: getDefaultValue('caminhoarquivoenergia2'),
    concessionaria: getDefaultValue('concessionaria'),
  }
}
