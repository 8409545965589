import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;  
`

export const PreviewContainer = styled.div`
  margin: 0 auto;
  overflow-y: auto;
  width: 330px;

  @media (min-width: 768px) {
    width: 100%;
    background-color: #cecece;
    height: 50vh;
  }

`

export const PageButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
