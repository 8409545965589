export const HomeIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={32}
    height={32}
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      stroke="#181D11"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M5.333 11.467V28h21.333V11.467"
    />
    <path
      stroke="#181D11"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M2.667 13.333 16 4l13.334 9.333M20 28v-8a2.666 2.666 0 0 0-2.667-2.667h-2.666A2.666 2.666 0 0 0 12 20v8"
    />
  </svg>
);
