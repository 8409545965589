import styled, {css} from 'styled-components'

export const TooltipContainer = styled.div<{open: boolean}>`
  ${(props) => css`opacity: ${props.open ? 1 : 0};`}
  width: 318px;
  height: 170px;
  z-index: 3;
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  left: 455px;

  p {
      position: absolute;
      padding: 30px;
      color: #181D11;
      font-family: Nexa Bold;
      font-size: 20px;
      font-style: normal;
      font-weight: 800;
      line-height: 28px; /* 140% */
      letter-spacing: -0.8px;
      z-index: 4;
  }

  span {
    color: #00B22A;
    font-family: Nexa Bold;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    letter-spacing: -0.8px;
  }

  @media (min-width: 1440px) {
    left: 565px;
  }
`

export const SavingsInfo = styled.div`
  border-radius: 12px;
  background: #181D11;
  padding: 16px;
  margin-top: auto;
  position: absolute;
  bottom: -300px;
  margin: 0 -8px;
  max-width: 336px;

  p {
    color: #FFF;
    font-family: Nexa Bold;
    font-size: 14px;
    font-weight: 800;
    line-height: 21px;
  }

  span {
    color: #00B22A;
    font-family: Nexa Bold;
    font-size: 14px;
    font-style: normal;
    font-weight: 800;
    line-height: 21px;
  }
`
