import MG from "assets/statesFlags/mg.png";
import GO from "assets/statesFlags/go.png";
import MT from "assets/statesFlags/mt.png";
import MS from "assets/statesFlags/ms.png";
import PE from "assets/statesFlags/pe.png";
import RJ from "assets/statesFlags/rj.png";
import SP from "assets/statesFlags/sp.png";
import { Option } from "components";

interface StateData {
  name: string;
  uf: string;
  stateFlag: string;
}

export const STATES: StateData[] = [
  {
    name: "Minas Gerais",
    uf: "MG",
    stateFlag: MG,
  },
  {
    name: "Góias",
    uf: "GO",
    stateFlag: GO,
  },
  {
    name: "Mato Grosso",
    uf: "MT",
    stateFlag: MT,
  },
  {
    name: "Mato Grosso do Sul",
    uf: "MS",
    stateFlag: MS,
  },
  {
    name: "Pernambuco",
    uf: "PE",
    stateFlag: PE,
  },
  {
    name: "Rio de Janeiro",
    uf: "RJ",
    stateFlag: RJ,
  },
  {
    name: "São Paulo",
    uf: "SP",
    stateFlag: SP,
  },
];

export const DUE_DATE_OPTIONS: Option[] = [
  {
    value: "05",
    label: "05",
  },
  {
    value: "10",
    label: "10",
  },
  {
    value: "15",
    label: "15",
  },
  {
    value: "20",
    label: "20",
  },
];
